import $ from 'jquery'
import '../external/ticker.js'

$(document).ready(function () {
  setDefaultArrowPointer()

  $('.statementCarousel__wrapper--carousel').ticker({
    item: 'div',
    pauseOnHover: true,
    speed: 110
  })
})

function setDefaultArrowPointer () {
  var css = document.createElement('style')
  css.type = 'text/css'
  css.innerHTML = '.statementCarousel__wrapper--carousel span.setArrowCursor {cursor: default!important;}'
  document.body.appendChild(css)
}
