import $ from 'jquery'

$(document).ready(function () {
  if ($('#checkoutError').length) {
    $('#checkoutError .btn__be-contacted').click(function () {
      $('#checkoutError .fail__section').hide()
      $('#checkoutError .contact__section').fadeIn()
    })

    // $('.header__wrapper .main__menu').hide()
  }
})
