import $ from 'jquery'

$(document).ready(function () {
  if ($('.blogListPosts').length) {
    const buttonShowMore = $('.blogListPosts__showMore')
    buttonShowMore.click(function (event) {
      event.preventDefault()
      appendBlogPosts()
      appendBlogPosts() // load two pages by click (feat NKI-314)
    })
  }
})

function appendBlogPosts () {
  const category = $('.blogListPosts__showMore').data('category')
  let page = parseInt($('.blogListPosts__showMore').data('page')) + 1
  $('.blogListPosts__showMore').data('page', page)
  page = parseInt($('.blogListPosts__showMore').data('page'))

  const sericeUrl = $('.blogListPosts__showMore').data('service') + '?page=' + page + '&category=' + category
  // console.log(sericeUrl)

  $.ajax({
    url: sericeUrl,
    async: false,
    success: function (data) {
      //   console.log(data)
      // Add new elements
      const newBlock = data && data.page && data.result && buildBlock(data.result, data.page)
      $('.blogListPosts .container.blogBlocks').append(newBlock)
      if (!data.showMore) {
        $('.blogListPosts__showMore')
          .parent()
          .parent()
          .parent()
          .fadeOut()
      }
    } // end success
  })
}

function buildBlock (block, page) {
  let lonelyBlock = ''
  let sixBlock = ''
  let treeBlock1 = ''
  let treeBlock2 = ''
  block.forEach((item, index) => {
    if (index === 0) {
      if (page % 2 === 0) {
        lonelyBlock = `<div class="row">\
                        <div class="col-lg-15">\
                            <div class="blogListPosts__itemLandscaspeWrapper imgLeft">\

                                <div class="item">\
                                    <div class="imgWrapper">\
                                      <a href="${(item.link && item.link.href) || ''}"\
                                        target="${(item.link && item.link.target) || ''}">\
                                      ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\
                                      </a>\
                                    </div>\
                                    <div class="info">\
                                        <span class="date">${item && item.date}</span>\
                                        <a href="${(item.link && item.link.href) || ''}"\
                                        target="${(item.link && item.link.target) || ''}">\
                                          <h3 class="title">${item && item.title}</h3>\
                                        </a>\
                                        <p class="content">${item && item.ingress}</p>\
                                    </div>\
                                </div>\

                            </div>\
                        </div>\
                    </div>`
      } else {
        lonelyBlock = `<div class="row">\
                          <div class="offset-lg-1 col-lg-15">\
                              <div class="blogListPosts__itemLandscaspeWrapper bg-sand imgRight">\



                                  <div class="item">\
                                      <div class="imgWrapper">\
                                        <a href="${(item.link && item.link.href) || ''}"\
                                        target="${(item.link && item.link.target) || ''}">\
                                          ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\

                                      </div>\
                                      <div class="info">\
                                          <span class="date">${item && item.date}</span>\
                                          <a href="${(item.link && item.link.href) || ''}"\
                                          target="${(item.link && item.link.target) || ''}">\
                                            <h3 class="title">${item && item.title}</h3>\
                                          </a>\
                                          <p class="content">${item && item.ingress}</p>\
                                      </div>\
                                  </div>\



                              </div>\
                          </div>\
                      </div>`
      }
    } else {
      if (index < 4) {
        treeBlock1 += `<div class="item">\
                          <div class="content">\
                              <a href="${(item.link && item.link.href) || ''}"\
                                target="${(item.link && item.link.target) || ''}">\
                                  <div class="imgWrapper">\
                                  ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\
                                  </div>\
                              </a>\
                              <div class="info">\
                              <span class="date">${item && item.date}</span>\
                              <a href="${(item.link && item.link.href) || ''}"\
                              target="${(item.link && item.link.target) || ''}">\
                                <h3 class="title">${item && item.title}</h3>\
                              </a>\
                              </div>\

                          </div>\
                      </div>`
      } else {
        treeBlock2 += `<div class="item">\
                          <div class="content">\
                              <a href="${(item.link && item.link.href) || ''}"\
                                target="${(item.link && item.link.target) || ''}">\
                                  <div class="imgWrapper">\
                                  ${item.image ? `<img src='${item.image.link}' alt='${item.image.alt}' title='${item.image.title}'/>` : ''}\
                                  </div>\
                              </a>\
                              <div class="info">\
                              <span class="date">${item && item.date}</span>\
                              <a href="${(item.link && item.link.href) || ''}"\
                              target="${(item.link && item.link.target) || ''}">\
                                <h3 class="title">${item && item.title}</h3>\
                              </a>\
                              </div>\

                          </div>\
                      </div>`
      }
    }
  })

  if (block.length > 1) {
    if (page % 2 === 0) {
      sixBlock = `<div class="row">\
                    <div class="offset-lg-1 col-lg-15">\
                        <div class="blogListPosts__itemsWrapper odd">\
                        ${treeBlock1}
                        </div>\
                    </div>\
                </div>

                <div class="row">\
                    <div class="offset-lg-1 col-lg-15">\
                        <div class="blogListPosts__itemsWrapper odd">\
                        ${treeBlock2}
                        </div>\
                    </div>\
                </div>`
    } else {
      sixBlock = `<div class="row">\
                        <div class="offset-lg-1 col-lg-15">
                        <div class="blogListPosts__itemsWrapper spin even">
                        ${treeBlock1}
                        </div>\
                    </div>\
                </div>

                <div class="row">\
                        <div class="offset-lg-1 col-lg-15">
                        <div class="blogListPosts__itemsWrapper spin even">
                        ${treeBlock2}
                        </div>\
                    </div>\
                </div>`
    }
  }

  return lonelyBlock + sixBlock
}
