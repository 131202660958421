import $ from 'jquery' //eslint-disable-line
import jQuery from 'jquery' //eslint-disable-line
import 'bootstrap/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import salesforceInstallmentsInvoice from './salesforce-installments-invoice'
import gaDataLayer from './ga-data-layer'
import coupon from './discount-coupon'
import documentation from './documentation'
import '../../external/sticky-sidebar.js'
import fnrvalidator from '../../external/fnrvalidator'
import * as formsLib from '../forms-validation'

var formDataAR = new FormData() //eslint-disable-line
const page = $('#checkoutCoursePageIDSalesforce')
const strings = page.data('strings')

$(document).ready(function () {
  if ($('#checkoutCoursePageIDSalesforce').length) {
    updateCookie()

    // $$$$$$$$$$$$$$$$$$ CODE FROM NKI-252 - START $$$$$$$$$$$$$$$$$$
    if (document.body.clientWidth > 720) {
      if ($('.header.header--steps') && $('.header.header--steps').length) {
        var sidebar = new StickySidebar('.header.header--steps', { // eslint-disable-line
          topSpacing: 0,
          leftSpacing: 0,
          minWidth: 0,
          bottomSpacing: 0,
          resizeSensor: true,
          containerSelector: '.page',
          innerWrapperSelector: '.header.header--steps'
        })
      }
    }
    // $$$$$$$$$$$$$$$$$$ CODE FROM NKI-252 - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ CODE FROM FRONTEND TEAM - START $$$$$$$$$$$$$$$$$$
    var dropDownCard = $('.dropDownCard1')
    var selectedItem = dropDownCard.find('.selectedItem')
    var dropDowntoggle = dropDownCard.find('.selectedItem, .arrowSelect')
    var option = dropDownCard.find('.option')
    var infoNAV = $('.infoNAV')
    dropDowntoggle.click(function () {
      dropDownCard.toggleClass('active')
    })
    option.click(function () {
      dropDownCard.toggleClass('active')
      selectedItem.html($(this).text())
      selectedItem.attr('data-value', $(this).text())
      option.removeClass('active')
      $(this).addClass('active')
      var thisValue = $(this).text()
      if (thisValue === '- Valg for annen betaler -') {
        infoNAV.hide()
        $('#otherPaymentName').attr('style', 'display: none')
        $('#otherPaymentEmail').attr('style', 'display: none')
      } else if (thisValue === 'NAV') {
        infoNAV.show()
        $('#otherPaymentName').attr('placeholder', 'NAV kontor').attr('style', 'display: block')
        $('#otherPaymentEmail').attr('placeholder', 'Saksbehandler').attr('style', 'display: block')
      } else {
        infoNAV.hide()
        $('#otherPaymentName').attr('placeholder', 'Navn').attr('style', 'display: block')
        $('#otherPaymentEmail').attr('placeholder', 'E-postadresse').attr('style', 'display: block')
      }
    })

    var dropDownCard2 = $('.dropDownCard2')
    var selectedItem2 = dropDownCard2.find('.selectedItem')
    var dropDowntoggle2 = dropDownCard2.find('.selectedItem, .arrowSelect')
    var option2 = dropDownCard2.find('.option')
    dropDowntoggle2.click(function () {
      dropDownCard2.toggleClass('active')
    })
    option2.click(function () {
      dropDownCard2.toggleClass('active')
      selectedItem2.html($(this).text())
      selectedItem2.attr('data-value', $(this).text())
      option2.removeClass('active')
      $(this).addClass('active')
    })

    $('.basket__item__box .styled-radio input').on('click', null, function () { // checkbox check checkout frontpage
      const classes = $(this).parent().attr('class')
      const classId = classes.split(' ')[1]

      $(`.${classId} input`).removeClass('active')
      $(this).addClass('active')

      $(this).parent().parent().addClass('active')
      $(this).parent().parent().removeClass('inactive')
      // formData.append('subjects', JSON.stringify(subjectArray))
    })

    // $$$$$$$$$$$$$$$$$$ CODE FROM FRONTEND TEAM - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ REMOVE PAYMENT METHOD IF AMOUNT = 0 - START $$$$$$$$$$$$$$$$$$
    removePaymentMethodIfNoAmount()
    // $$$$$$$$$$$$$$$$$$ REMOVE PAYMENT METHOD IF AMOUNT = 0 - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ DOCUMENTATION SECTION - START $$$$$$$$$$$$$$$$$$
    documentation.checkAdmissionRequeriment($)
    documentation.setDocumentationForm($)
    documentation.sendForm($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError)
    documentation.populateArrayOfFiles($)
    // $$$$$$$$$$$$$$$$$$ DOCUMENTATION SECTION - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - START $$$$$$$$$$$$$$$$$$
    const backPage = $('.checkout__title .back-link a, .back-step-mobile a')
    backPage.click(function () {
      if ($(this).attr('href') === 'javascript:void(0)') {
        if ($('.step3').is(':visible')) { // Back from step3 to step2
          $('.step3').hide()
          $('.header-step3').removeClass('active')
          !$('.header-step3').hasClass('done') && $('.header-step3').addClass('done')

          if ($('.stepOptionalDocumentation').length) { // If stepOptionalDocumentation exists, back from step3 to it
            $('.stepOptionalDocumentation').show()
            $('.header-stepOptionalDocumentation').addClass('active')
            $('.header-stepOptionalDocumentation').removeClass('done')
          } else { // If stepOptionalDocumentation not exists, back from step3 to step2
            $('.step2').show()
            $('.header-step2').addClass('active')
            $('.header-step2').removeClass('done')
          }
        } else if ($('.stepOptionalDocumentation').is(':visible')) { // Back from stepOptionalDocumentation to step2
          $('.stepOptionalDocumentation').hide()
          $('.header-stepOptionalDocumentation').removeClass('active')
          !$('.header-stepOptionalDocumentation').hasClass('done') && $('.header-stepOptionalDocumentation').addClass('done')

          $('.step2').show()
          $('.header-step2').addClass('active')
          $('.header-step2').removeClass('done')
        } else if ($('.step2').is(':visible')) { // Back from step2 to step1
          $('.step2').hide()
          $('.header-step2').removeClass('active')
          !$('.header-step2').hasClass('done') && $('.header-step2').addClass('done')

          $('.step1').show()
          $('.header-step1').addClass('active')
          $('.header-step1').removeClass('done')
        } else {
          goBack()
        }
      }
    })
    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ STEP1 CTA - START $$$$$$$$$$$$$$$$$$
    const buttonStep1 = $('#btn-step1')

    if (buttonStep1.length) {
      const cartExist = page.data('cart-exist')
      if (cartExist) {
        const cartId = page.data('cartid-from-req')
        if ($.cookie('shoppingCartIdSalesforce') === undefined && cartId) {
          $.cookie('shoppingCartIdSalesforce', cartId, { expires: 365, path: '/' })
        }
      }
    }
    // $$$$$$$$$$$$$$$$$$ STEP1 CTA - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ LOAD CONFIRMATION PAGE AFTER NETAXEPT PAYMENT - START $$$$$$$$$$$$$$$$$$
    // const transactionId = page.data('transaction-id')
    const cartIdFromReq = page.data('cartid-from-req')
    // const responseCode = page.data('response-code')
    const authorizePaymentResponse = page.data('authorize-payment-response')
    if (cartIdFromReq && authorizePaymentResponse) {
      $('.step1').hide()
      $('.step2').hide()
      $('.header-stepOptionalDocumentation').hide()
      $('.step3').hide()
      $('.step4').show()

      $('.header-step1').removeClass('active')
      $('.header-step1').addClass('disabled')
      $('.header-step4').removeClass('disabled')
      $('.header-step4').addClass('active')

      const customerInfo = page.data('get-cart-customer-info')

      if (customerInfo) {
        $.ajax({
          url: customerInfo,
          type: 'POST',
          data: jQuery.param({ cartIdFromReq: cartIdFromReq }),
          success: function (data) {
            if (data) {
              if (data.cartStatus === 'checkout_complete') {
                gaDataLayer.processGADataLayerPurchase($, cartIdFromReq, 'creditcard')

                // append to section: Takk for din bestilling
                $('.checkout__text-info.withTitle.col-lg-8').append(`<ul>
              <li>
                I løpet av et par dager vil du motta bekreftelse på din
                påmelding samt bruker og passord til din e-post: <a
                   ${data.inputEmail ? `href=mailto: ${data.inputEmail}` : ''}
                   class="link-b-orange">${data.inputEmail ? `${data.inputEmail}` : ''}</a>
              </li>
            </ul>
            <h6>Er det noe du lurer på? Kontakt oss gjerne:</h6>
            <p>man-fre: 09-16</p>
            <p>67 58 88 00</p>`)

                // append to section: Student
                $('.checkout__section.student.customerInformation').append(`<div class="row resize-col">
              <div class="form-group form-readonly col-md-8 col-lg-4">
                <label for="inputName">Navn</label>
                ${data.inputName && data.inputLastName ? `<div>${data.inputName} ${data.inputLastName}</div>` : ''}
              </div>
              <div class="form-group form-readonly col-md-8 col-lg-4">
                <label for="inputLastName">Personnummer</label>
                ${data.inputSocialNumber ? `<div>${data.inputSocialNumber}</div>` : ''}
              </div>
            </div>
            <div class="row resize-col">
              <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputEmail">E-post </label>
              ${data.inputEmail ? `<div>${data.inputEmail}</div>` : ''}
              </div>
              <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputPhoneNumber">Telefonnummer</label>
                ${data.inputPhoneNumber ? `<div>${data.inputCountryCode || ''}${data.inputPhoneNumber}</div>` : ''}
              </div>
            </div>
            <div class="row resize-col">
              <div class="form-group form-readonly col-md-8 col-lg-4">
                <label for="inputAddress">Gate/postboks</label>
                ${data.inputAddress ? `<div>${data.inputAddress}</div>` : ''}
              </div>
              <div class="form-group form-readonly col-md-8 col-lg-4">
                <label for="inputNumber">Sted</label>
                ${data.inputZipCode ? `<div>${data.inputZipCode}</div>` : ''}
              </div>
              <div class="form-group form-readonly col-md-8 col-lg-4">
                <label for="inputLand">Land</label>
                ${data.inputCountry && data.inputCountry.name ? `<div>${capitalize(data.inputCountry.name, true)}</div>` : ''}
              </div>
            </div>`)

                const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
                const customerPaymentOption = {
                  paymentOption: 'credit-card', paymentOptionVariant: undefined, numberOfDownPaymentMonths: 0
                }
                $.ajax({
                  url: studentRegisterSalesforceService,

                  data: { shoppingCartId: page.data('cartid-from-req'), action: 'opportunity', paymentStep: true, customerPaymentOption: JSON.stringify(customerPaymentOption) },
                  success: function (data) {
                    if (data) {}
                  }
                })
              }
            }
          } // end success
        })
      }

      const removeCookie = page.data('remove-cookie')
      if (removeCookie) {
        const cartIdToBeRemoved = $.cookie('shoppingCartIdSalesforce')
        const cartIdFromReq = page.data('cartid-from-req')
        $.removeCookie('shoppingCartIdSalesforce', cartIdToBeRemoved)
        $.removeCookie('shoppingCartIdSalesforce', cartIdFromReq)
        $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
      }
    } else if (cartIdFromReq && authorizePaymentResponse === false) {
      const cart = checkCartExist()

      if (cart && cart.data && (cart.data.items || cart.data.courseItems)) {
        gaDataLayer.processGADataLayerCheckoutSteps($, cartIdFromReq, 2)

        const booleanSetHeaders = !$('#btn-setDocumentation').length
        createPersonalInformation(booleanSetHeaders)

        appendErrorMessage($('.step3 .messageError'))

        $('.step1').hide()
        $('.step2').hide()
        $('.stepOptionalDocumentation').hide()
        $('.step3').show()

        $('.header-step1').removeClass('active')
        $('.header-step1').addClass('done')

        $('.header-step2').removeClass('disabled')
        $('.header-step2').addClass('done')

        $('.header-stepOptionalDocumentation').removeClass('disabled')
        // $('.header-stepOptionalDocumentation').removeClass('active')
        $('.header-stepOptionalDocumentation').addClass('done')

        if ($('#btn-setDocumentation').length) {
          // documentation.saveDocuments($, jQuery, checkCartExist)

          $('.header-stepOptionalDocumentation').removeClass('active')
          $('.header-stepOptionalDocumentation').addClass('done')

          $('.header-step3').removeClass('disabled')
          $('.header-step3').addClass('active')

          $('.stepOptionalDocumentation').hide()
          $('.step3').show()

          $('#btn-setDocumentation').trigger('click')
        }
      }
    }
    // $$$$$$$$$$$$$$$$$$ LOAD CONFIRMATION PAGE AFTER NETAXEPT PAYMENT - END $$$$$$$$$$$$$$$$$$

    setDefaultArrowPointer()

    // $$$$$$$$$$$$$$$$$$ FRONTEND MANIPULATE RADIO BUTTON - START $$$$$$$$$$$$$$$$$$
    const radioCard = $('.radio-card')
    const itemRadioCard = radioCard.find("input[type='radio']")

    radioCard.click(function () {
      itemRadioCard.removeClass('active')
      $(this).find("input[type='radio']").addClass('active')
      proccessInstallmentsOption($(this).find($('#option3')).length > 0)

      const selectedOption = $('.radio-card').find("input[type='radio'].active").attr('id')
      if (selectedOption === 'option4') {
        $('.option4.alternativePayment').attr('style', 'overflow: unset')
      } else {
        $('.option4.alternativePayment').attr('style', 'overflow: hidden')
      }
    })
    // $$$$$$$$$$$$$$$$$$ FRONTEND MANIPULATE RADIO BUTTON - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ STEP2 CTA - SAVE USER INFORMATION ON DATABASE - START $$$$$$$$$$$$$$$$$$
    if ($('#btn-step2').length) {
      document.getElementById('btn-step2').addEventListener('click', createPersonalInformation, false)
    }
    // $$$$$$$$$$$$$$$$$$ STEP2 CTA - SAVE USER INFORMATION ON DATABASE - END $$$$$$$$$$$$$$$$$$

    // $$$$$$$$$$$$$$$$$$ REDIRECT TO ADD MORE COURSES CTA - START $$$$$$$$$$$$$$$$$$
    const rediretcToCoursePageButton = $('.basket__item--add')
    rediretcToCoursePageButton.click(function () {
      window.location.href = page.data('url')
    })
    // $$$$$$$$$$$$$$$$$$ REDIRECT TO ADD MORE COURSES CTA - END $$$$$$$$$$$$$$$$$$

    const buttonStep1ToStep2 = $('#btn-step1')
    buttonStep1ToStep2.on('click', null, function () {
      updateCookie()

      const button = $(this)
      setLoadButton($(this))

      const cart = checkCartExist()

      if (!cart || !cart.data || (!cart.data.items && !cart.data.courseItems)) {
        removeLoadButton($(this))
        displaySalesforcePageError($('#btn-step1'))
        $('.checkout__section.basket__item .btn.btn-remove').trigger('click')
        return
      }

      if ($('.basket__item__box.inactive').length) {
        goToScrollTop($('.basket__item__box.inactive'))
        removeLoadButton($(this))
        return
      }

      $('.step1').hide()
      $('.step2').show()

      $('.header-step1').removeClass('active')
      $('.header-step1').addClass('done')

      $('.header-step2').removeClass('disabled')
      if ($('.header-step2').hasClass('done')) $('.header-step2').removeClass('done')
      $('.header-step2').addClass('active')

      goToScrollTop($('.header.header--steps'))

      const subjectArray = []
      $('.basket__item__box .styled-radio input.active').each(function (index) {
        const classes = $(this).parent().attr('class')
        const courseId = classes.split(' ')[1]

        subjectArray.push({ [courseId]: $(this).parent().attr('value') })
      })

      const checkoutService = page.data('service')
      const shoppingCartId = page.data('cartid-from-req')

      if (subjectArray.length) {
        $.ajax({
          url: checkoutService,
          type: 'GET',
          data: { shoppingCartId: shoppingCartId, subjects: JSON.stringify(subjectArray) },
          success: function (data) {
            removeLoadButton(button)
          }
        })
      } else {
        removeLoadButton(button)
      }

      gaDataLayer.processGADataLayerCheckoutSteps($, shoppingCartId, 2)
    })

    // $$$$$$$$$$$$$$$$$$ HEADER BUTTONS TO CONTROL CHECKOUT FLOW - START $$$$$$$$$$$$$$$$$$
    const stepTabs = $('.header--steps .main__menu .item')

    stepTabs.on('click', null, function () {
      if (!$(this).hasClass('disabled')) {
        if (!$(this).hasClass('active')) {
          const idStepToHide = $('.header--steps .main__menu .item.active').attr('id')
          const stepToHide = idStepToHide && idStepToHide.split('-')[1]
          $(`#${idStepToHide}`).addClass('done')
          $(`#${idStepToHide}`).removeClass('active')

          $(this).removeClass('done')
          $(this).addClass('active')

          const idStepToShow = $(this).attr('id')
          const stepToShow = idStepToShow && idStepToShow.split('-')[1]

          $(`.${stepToHide}`).hide()
          $(`.${stepToShow}`).show()
        }
      }
    })
    // $$$$$$$$$$$$$$$$$$ HEADER BUTTONS TO CONTROL CHECKOUT FLOW - END $$$$$$$$$$$$$$$$$$

    const buttonStep3ToStep4 = $('#btn-step3')
    buttonStep3ToStep4.on('click', null, function () {
      updateCookie()
      const acceptTerms = $('#terms').hasClass('active')
      if (!acceptTerms) {
        $('#terms').addClass('invalid')
        $('html, body').animate(
          {
            scrollTop: $('#terms').parent().parent().offset().top - 200
          }, 700)
        return
      }
      $('#terms').removeClass('invalid')

      const cart = checkCartExist()

      if (!cart || !cart.data || (!cart.data.items && !cart.data.courseItems) || !cart.data.salesforceCustomerId) {
        displaySalesforcePageError($('#btn-step3'))
        return
      }

      const correctDocumentation = documentation.verifyDocumentation($)
      if (!correctDocumentation && $('.stepOptionalDocumentation').length) {
        const documentationStepHeader = $('#header-stepOptionalDocumentation')
        const paymentStepHeader = $('#header-step3')
        paymentStepHeader.removeClass('active')
        paymentStepHeader.addClass('disabled')

        documentationStepHeader.removeClass('done')
        documentationStepHeader.addClass('active')

        $('.step3').hide()
        $('.stepOptionalDocumentation').show()

        goToScrollTop($('.stepOptionalDocumentation'))
        return
      }

      const invalidCoupon = $('#inputDiscountCoupon:visible').hasClass('invalidField')
      if (invalidCoupon) {
        goToScrollTop($('#inputDiscountCoupon'), 250)
        return
      }

      const continueCheckout = true
      const paymentMessageBox = $('.payment-method-message')

      const optionRadioChecked = $('.checkout__section .radio-card input.active')
      const optionRadioId = optionRadioChecked.attr('id')
      if (!optionRadioId) {
        goToScrollTop($('.checkout__section .radio-card input'), 265)
        paymentMessageBox.show()
      } else {
        paymentMessageBox.hide()
      }

      if (continueCheckout) {
        handleSelectedPaymentMethod(optionRadioId)
      }
    })

    // remove courses from basket
    const buttonRemoveCourse = $('.checkout__section.basket__item .btn.btn-remove')
    buttonRemoveCourse.on('click', null, function () {
      checkCartExist()
      const removeItemCartUrl = page.data('remove-item-cart')
      const idProgramToRemove = $(this).attr('id')
      const isCourse = $(this).hasClass('remove-course')
      const shoppingCartId = page.data('cartid-from-req')
      const itemToRemove = $(this).parent()

      gaDataLayer.processGADataLayerRemoveFromCart($, idProgramToRemove, shoppingCartId)
      $.ajax({
        url: removeItemCartUrl,
        data: jQuery.param({ shoppingCartId: shoppingCartId, idProgramToRemove: idProgramToRemove, isCourse: isCourse }),
        success: function (data) {
          const installmentsOptionIsSelected = $('.radio-card').find("input[type='radio']#option3.active").length > 0

          if (data && (data.programRemoved === true)) {
            itemToRemove.remove()
            $(`#item-step2-${idProgramToRemove}`).remove()
            $(`#item-step-optional-${idProgramToRemove}`).remove()
            $(`#item-step3-${idProgramToRemove}`).remove()
            $(`#item-step4-${idProgramToRemove}`).remove()
            $('.basket__total .basket__total__value .value').remove()
            $('.basket__total .basket__total__value').append(`<div class="value">${data.updatedTotalPriceWithMask}</div></div>`)

            $('.checkout__section .checkout__close__basket .checkout__close__basket__total .value').remove()
            $('.checkout__section .checkout__close__basket .checkout__close__basket__total').append(`<div class="value">${data.updatedTotalPriceWithMask}</div></div>`)

            page.data('price', data.updatedTotalPrice)
            page.data('amount', data.updatedTotalPrice)
            const minValueToAllowInstallments = page.data('min-amount-to-allow-more-installments')

            removePaymentMethodIfNoAmount()
            if (data.updatedTotalPrice > 0) {
              const installments18MonthsOptionElement = document.getElementById('instalmentOption5')
              if (!data.shouldEnable18InstallmentsOption && installments18MonthsOptionElement) {
                installments18MonthsOptionElement.remove()
              }
              if (data.updatedTotalPrice < minValueToAllowInstallments) {
                $('#option3').parent().remove()
                $('li#interestAndFees').remove()
              } else {
                updateInstallmentsOptions({ totalInCart: data.updatedTotalPrice, minValueToAllowInstallments })

                if (installmentsOptionIsSelected) {
                  updateCartPriceAndCheckInterestRate({ totalPrice: { priceWithDiscount: data.totalPriceWithDiscount } })
                }
              }
            }

            if (!data.admissionRequeriments) {
              $('.header-stepOptionalDocumentation').hide()
            }

            if (!data.totalCartItems) {
              $('#btn-step1').hide()

              $('.header-step2').removeClass('active done')
              $('.header-step2').addClass('disabled')
              $('.header-stepOptionalDocumentation').removeClass('active done')
              $('.header-stepOptionalDocumentation').addClass('disabled')
              $('.header-step3').removeClass('active done')
              $('.header-step3').addClass('disabled')
              $('.header-step4').removeClass('active done')
              $('.header-step4').addClass('disabled')
            } else {
              $('#btn-step1').show()
            }
          }

          // The coupon operations will not performed the callback (see third param as false) at this case because the updateCartPriceAndCheckInterestRate already performs the same function
          if ($('#inputDiscountCoupon').val()) {
            couponOperations(true, false, !installmentsOptionIsSelected)
          }
        } // end success
      })
    })

    $('.newsletter').on('click', null, function () {
      $(this).toggleClass('active')
    })
    newsletterRegistration()

    checkBoxstudyRegulations()

    checkBoxConsent()

    couponOnChange()

    questionOnChange()

    countCharacteresTextArea()

    HistoryButtonOverride(() => {
      if ($('#header-step2').hasClass('active')) {
        $('.step1').show()
        $('.step2').hide()

        $('.header-step2').removeClass('active')
        $('.header-step2').addClass('done')

        $('.header-step1').removeClass('disabled')
        $('.header-step1').removeClass('done')
        $('.header-step1').addClass('active')
        return true
      }

      if ($('#header-stepOptionalDocumentation').length && $('#header-stepOptionalDocumentation').hasClass('active')) {
        $('.step2').show()
        $('.stepOptionalDocumentation').hide()

        $('.header-stepOptionalDocumentation').removeClass('active')
        $('.header-stepOptionalDocumentation').addClass('done')

        $('.header-step2').removeClass('disabled')
        $('.header-step2').addClass('active')
        return true
      }

      if ($('#header-step3').hasClass('active')) {
        $('.step3').hide()
        $('.header-step3').removeClass('active')
        $('.header-step3').addClass('done')

        if ($('#header-stepOptionalDocumentation').length) {
          $('.stepOptionalDocumentation').show()
          $('.header-stepOptionalDocumentation').removeClass('disabled')
          $('.header-stepOptionalDocumentation').addClass('active')
        } else {
          $('.step2').show()
          $('.header-step2').removeClass('disabled')
          $('.header-step2').addClass('active')
        }
        return true
      }
    }, () => {
      if ($('#header-step1').hasClass('active') && $('#header-step2').hasClass('done')) {
        $('.step1').hide()
        $('.step2').show()

        $('.header-step1').removeClass('active')
        $('.header-step1').addClass('done')

        $('.header-step2').removeClass('disabled')
        $('.header-step2').addClass('active')
        return true
      }

      if ($('#header-step2').hasClass('active') && $('#header-stepOptionalDocumentation').length && $('#header-stepOptionalDocumentation').hasClass('done')) {
        $('.step2').hide()
        $('.stepOptionalDocumentation').show()

        $('.header-step2').removeClass('active')
        $('.header-step2').addClass('done')

        $('.header-stepOptionalDocumentation').removeClass('disabled')
        $('.header-stepOptionalDocumentation').addClass('active')
        return true
      }

      if ($('#header-step2').hasClass('active') && !$('#header-stepOptionalDocumentation').length && $('#header-step3').hasClass('done')) {
        $('.step2').hide()
        $('.step3').show()

        $('.header-step2').removeClass('active')
        $('.header-step2').addClass('done')

        $('.header-step3').removeClass('disabled')
        $('.header-step3').addClass('active')
        return true
      }

      if ($('#header-stepOptionalDocumentation').length && $('#header-stepOptionalDocumentation').hasClass('active') && $('#header-step3').hasClass('done')) {
        $('.stepOptionalDocumentation').hide()
        $('.step3').show()

        $('.header-stepOptionalDocumentation').removeClass('active')
        $('.header-stepOptionalDocumentation').addClass('done')

        $('.header-step3').removeClass('disabled')
        $('.header-step3').addClass('active')
        return true
      }
    })

    formsLib.addInputEventListeners()

    // $$$$$$$$$$$$$$$$$$ FRONTEND MANIPULATE COUPON RADIO BUTTON - START $$$$$$$$$$$$$$$$$$
    const radioCoupon = $('.checkout__section.couponCode')
    const inputRadioCoupon = radioCoupon.find(".couponCode__checkbox input[type='checkbox']")
    const labelRadioCoupon = radioCoupon.find('.couponCode__checkbox label')
    const couponWrapper = radioCoupon.find('.couponCode__wrapper')

    labelRadioCoupon.click(function () {
      inputRadioCoupon.toggleClass('active')
      couponWrapper.toggleClass('active')
    })
    // $$$$$$$$$$$$$$$$$$ FRONTEND MANIPULATE COUPON RADIO BUTTON - END $$$$$$$$$$$$$$$$$$
  }
})

/**
 * character count for the text area fields of the checkout forms
 */
function countCharacteresTextArea () {
  document.getElementById('question').onkeyup = function () {
    const count = document.getElementById('countCharacterQuestion')
    count.innerHTML = `${(25000 - this.value.length)}/25000`
    if (this.value.length === 25000) {
      $('#countCharacterQuestion').css({ color: 'red' })
    } else {
      $('#countCharacterQuestion').css({ color: 'gray' })
    }
  }
}

/**
 * verifies that the selected payment option was "installment", if it is, recalculates the shopping cart price based on the number of selected installments and displays it on the screen.
 * It recalculates the price returning to the original if the selected payment option is not "installments"
 * @param {Boolean} isInstallmentOption indicates if the selected payment option is "Installments"
 */
function proccessInstallmentsOption (isInstallmentOption) {
  if (isInstallmentOption) {
    const shoppingCartId = page.data('cartid-from-req')
    const serviceUrl = page.data('checkout-courses-general-service')

    $.ajax({
      url: serviceUrl,
      // async: false,
      data: { shoppingCartId: shoppingCartId, action: 'retrievePrice' },
      success: function (data) {
        if (data) {
          updateCartPriceAndCheckInterestRate(data)
        }
      } // end success
    })
  } else {
    $('li#interestAndFees').remove()
    const dataPrice = coupon.retrieveTotalPrice($)
    if (dataPrice && dataPrice.totalPrice && dataPrice.totalPrice.totalPriceWithMask) {
      $('.step3 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.step4 .checkout__close__basket__total .value').text(dataPrice.totalPrice.totalPriceWithMask)
    }
  }
}

/**
 * Update the installments options available according to the cart being updated. It checks the options based on the total in cart
 * @param {Object} params
 * @param {Number} params.totalInCart
 * @param {Number} params.minValueToAllowInstallments
 * @returns
 */
function updateInstallmentsOptions ({ totalInCart, minValueToAllowInstallments }) {
  const shouldAllowUpTo3Months = totalInCart >= minValueToAllowInstallments
  const shouldAllowUpTo6Months = totalInCart >= 15000
  const shouldAllowUpTo9Months = totalInCart >= 30000
  const shouldAllowUpTo12Months = totalInCart >= 45000

  const installmentOption5Element = document.getElementById('instalmentOption5') // 18 months option

  const disableInstallmentOption = (element) => {
    element.hide()
    element.removeClass('active')
    element.removeClass('selected')
  }

  const selectInstallmentOption = (element) => {
    if (installmentOption5Element) return

    element.addClass('selected')
    element.addClass('active')
    $('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.instalments .selectedItem').text(element.html())
    $('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').attr('data-value', element.html())
  }

  if (shouldAllowUpTo12Months) {
    return
  }

  if (shouldAllowUpTo9Months) {
    disableInstallmentOption($('#instalmentOption4')) // Disables 12 month option
    selectInstallmentOption($('#instalmentOption1'))
    return
  }

  if (shouldAllowUpTo6Months) {
    disableInstallmentOption($('#instalmentOption4')) // Disables 12 month option
    disableInstallmentOption($('#instalmentOption3')) // Disables 9 month option
    selectInstallmentOption($('#instalmentOption1'))
    return
  }

  if (shouldAllowUpTo3Months) {
    disableInstallmentOption($('#instalmentOption4')) // Disables 12 month option
    disableInstallmentOption($('#instalmentOption3')) // Disables 9 month option
    disableInstallmentOption($('#instalmentOption2')) // Disables 6 month option

    selectInstallmentOption($('#instalmentOption1'))

    // if the 18 months options is available, there will beb at least 2 installments options (3 and 18). So the elements below shouldn't be removed.
    if (installmentOption5Element) return

    $('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.instalments .options').remove()
    $('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.instalments').removeClass('item dropDownCard dropDownCard2 instalments')
  }
}

/**
 * Updates the cart total checking if there are interest rates
 * @param {Object} data
 * @param {Object} data.totalPrice
 * @param {Number} data.totalPrice.priceWithDiscount
 */
function updateCartPriceAndCheckInterestRate (data) {
  const dataPrice = data && data.totalPrice
  const price = dataPrice && dataPrice.priceWithDiscount
  const oldTotalPrice = dataPrice && dataPrice.oldTotalPrice
  const interestRate = page.data('interestrate')
  const monthsOption = parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text())

  const sumInterest = (price / 100) * ((interestRate / 12) * monthsOption)
  const monthlyFee = page.data('monthlyfee')
  const sumMonthlyFee = monthlyFee * monthsOption

  const setupFee = page.data('setupfee')

  const interestAndFees = parseFloat(setupFee) + parseFloat(sumInterest) + parseFloat(sumMonthlyFee)
  const effectiveInterestRate = ((interestAndFees * 100) / price).toFixed(2)

  const htmlCouponDiscount = $('.step3 .couponDiscountBasket').html()
  $('.step3 li.couponDiscountBasket, .step4 li.couponDiscountBasket').remove()

  $('li#interestAndFees').remove()
  const basketItems = $('.step3 .checkout__bottom.basket__total .basket__total__items, .checkout__section .checkout__close__basket .items')

  if (htmlCouponDiscount) {
    basketItems.append(
      `
      <li class="couponDiscountBasket">
        ${htmlCouponDiscount}
      </li>
      `
    )
  }

  basketItems.append(
    `
    <li id="interestAndFees">
      <div>Renter og Gebyrer (Effektiv rente ${effectiveInterestRate}%)</div>
      <div class="value">${formatMoney(interestAndFees)}</div>
    </li>
    `
  )
  $('.step3 .basket__total__value .value').text(formatMoney(price + interestAndFees))
  $('.step4 .checkout__close__basket__total .value').text(formatMoney(price + interestAndFees))
}

/**
 * formats the shopping cart value to the currency format defined in the project
 * @param {Number} amount value to be converted
 * @param {Number} decimalCount number of decimal unit
 * @param {String} decimal separator used for decimal unit
 * @param {String} thousands separator used for the thousand unit
 * @returns {String} the amount converted to the currency format defined in the project
 */
function formatMoney (amount, decimalCount = 2, decimal = ',', thousands = ' ') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0

    const newAmount = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    const newAmountWithMask = (`kr ${newAmount}`).replace(',00', ',-')
    return newAmountWithMask
  } catch (e) {}
}

/**
 * verifies that the key of the stored cookie is the same as the page request. If not, the cookie is updated so that it tracks the request key
 */
function updateCookie () {
  // if cartId from request != cookie cartId
  // then check if cart exists.
  // if cart exist then update the cookie
  const cartIdFromReq = page.data('cartid-from-req')
  const cartIdFromCookie = $.cookie('shoppingCartIdSalesforce')
  if (cartIdFromReq) {
    if (cartIdFromReq !== cartIdFromCookie) {
      const checkCartExistServiceUrl = page.data('check-cart-exist')
      $.ajax({
        url: checkCartExistServiceUrl,
        type: 'POST',
        async: false,
        data: jQuery.param({ shoppingCartId: cartIdFromReq }),
        success: function (data) {
          if (data && (data.cartExist)) {
            $.cookie('shoppingCartIdSalesforce', cartIdFromReq, { expires: 365, path: '/' })
          }
        } // end success
      })
    }
  }
}

/**
 * performs coupon operations as soon as the user changes the value typed in the field
 */
function couponOnChange () {
  $('#inputDiscountCoupon').on('change', function () {
    couponOperations()
  })
}

/**
 * performs coupon operations as soon as the user changes the value typed in the field
 */
function questionOnChange () {
  $('#question').on('change', function () {
    const button = $('#btn-step3')
    const serviceUrl = page.data('service')
    const question = $('#question').val()
    const cartIdFromReq = page.data('cartid-from-req')

    // if (question.length) {
    setLoadButton(button)
    $.ajax({
      url: serviceUrl,
      type: 'POST',
      // async: false,
      data: { shoppingCartId: cartIdFromReq, question: question || ' ' },
      success: function (data) {
        if (data && (data.status === 'success')) {
          $('#questionInfo').remove()
          removeLoadButton(button)
        } else {
          if (!$('#questionInfo').length) {
            $('.checkout__section.formGroup.questionInfo').append(
              `<div class="row resize-col" id="questionInfo">
                <div class="col-lg-10">
                  <div class="box-orange">
                    <p>Et problem oppstod under kommunikasjon med serveren</p>
                  </div>
                </div>
              </div>`
            )
            removeLoadButton(button)
          }
        }
      } // end success
    })
  })
}

/**
 *performs coupon operations, updating the total value of the shopping cart on the checkout page if it is a valid coupon
 * @param {Boolean} getCouponFromCart indicates whether the coupon code should be retrieved from the current shopping cart
 * @param {Boolean} updateSalesforceOpportunity indicates whether the Salesforce opportunity should be updated or not
 * @param {Boolean} [shouldRunCallback=true] indicates if the callback should be performed. Default is true
 */
function couponOperations (getCouponFromCart = false, updateSalesforceOpportunity = true, shouldRunCallback = true) {
  // const button = $('#btn-step3')
  let couponCodeFromCart
  if (getCouponFromCart) {
    couponCodeFromCart = coupon.getCouponCode($)
  }

  coupon.applyCouponDiscount($, jQuery, couponCodeFromCart, updateSalesforceOpportunity, createOrUpdateOpportunitySalesforce, displaySalesforcePageError, shouldRunCallback ? () => proccessInstallmentsOption($('.radio-card').find("input[type='radio']#option3.active").length > 0) : undefined)

  // if (updateSalesforceOpportunity) {
  //   setLoadButton(button)
  //   window.setTimeout(function () {
  //   // do whatever you want to do
  //     const result = createOrUpdateOpportunitySalesforce(page.data('cartid-from-req'))
  //     const orderId = result && result.orderId
  //     if (!orderId) {
  //     // removeLoadButton(button)
  //       displaySalesforcePageError($('#inputDiscountCoupon'))
  //     }
  //     removeLoadButton(button)
  //   }, 1000)
  // }
}

/**
 * makes a request to the backend to persist the payment option selected by the customer in the shopping cart
 */
function setCustomerPaymentOption () {
  const shoppingCartId = page.data('cartid-from-req')
  const selectedOption = $('.radio-card').find("input[type='radio'].active").attr('id')
  const otherPayerVariant = $('.option4.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.dropDownCard1 .selectedItem').attr('data-value')
  let paymentOption = ''
  let paymentOptionVariant
  switch (selectedOption) {
    case 'option1':
      paymentOption = 'credit-card'
      break

    case 'option2':
      paymentOption = 'invoice'
      break

    case 'option3':
      paymentOption = 'installments'
      break
    case 'option4':
      paymentOption = 'other-payer'
      paymentOptionVariant = otherPayerVariant
      break
  }

  const numberOfDownPaymentMonths = $('#option3').hasClass('active') ? parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text()) : 0
  $.ajax({
    url: page.data('checkout-courses-general-service'),
    async: false,
    data: { shoppingCartId: shoppingCartId, action: 'setCustomerPaymentOption', paymentOption: paymentOption, paymentOptionVariant: paymentOptionVariant, numberOfDownPaymentMonths: numberOfDownPaymentMonths },
    success: function (data) {
      if (data) {}
    } // end success
  })
}

function getCustomerPaymentOption () {
  const selectedPaymentOption = $('.radio-card').find("input[type='radio'].active").attr('id')
  const otherPayerVariant = $('.option4.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.dropDownCard1 .selectedItem').attr('data-value')
  let paymentOption = ''
  let paymentOptionVariant
  switch (selectedPaymentOption) {
    case 'option1':
      paymentOption = 'credit-card'
      break

    case 'option2':
      paymentOption = 'invoice'
      break

    case 'option3':
      paymentOption = 'installments'
      break
    case 'option4':
      paymentOption = 'other-payer'
      paymentOptionVariant = otherPayerVariant
      break
  }
  const numberOfDownPaymentMonths = $('#option3').hasClass('active') ? parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text()) : 0

  return {
    paymentOption: paymentOption, paymentOptionVariant: paymentOptionVariant, numberOfDownPaymentMonths: numberOfDownPaymentMonths
  }
}

/**
 * tick the radio button for acceptance of terms at checkout
 */
function checkBoxstudyRegulations () {
  $('#terms').on('click', null, function () {
    $(this).toggleClass('active')
  })
}

/**
 * tick the radio button for consent
 */
function checkBoxConsent () {
  $('#consent').on('click', null, function () {
    $(this).toggleClass('active')
  })
}

/**
 * activates a button spinner
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

/**
 * make a request to the backend to subscribe the customer to the site's newsletter
 */
function newsletterRegistration () {
  const newsletterServiceUrl = page.data('newsletter-service')
  const cartIdFromReq = page.data('cartid-from-req')

  const footer = $('footer')
  const newsletterConfirmationPage = footer.data('newsletter-confirmation-page')

  // btn-step4

  const buttonNewsletter = $('#btn-step4')

  buttonNewsletter.on('click', null, function () {
    if (!termsWereChecked()) {
      return
    }

    const inputNewsletter = $('.newsletter.active')
    if (inputNewsletter.length) {
      $.ajax({
        url: newsletterServiceUrl,
        data: jQuery.param({ cartId: cartIdFromReq }),
        success: function (data) {
          if (data && (data.status === 'success')) {
            // TODO:
            buttonNewsletter.hide()
            if (newsletterConfirmationPage) {
              window.location.href = newsletterConfirmationPage
            }
          } else {
            // TODO:
          }
        } // end success
      })
    }
  })
}

/**
 * removes payment options if purchase amount is zero
 */
function removePaymentMethodIfNoAmount () {
  const amount = page.data('amount')
  if (amount <= 0) {
    $('#option1').parent().parent().remove()
    $('#inputDiscountCoupon').parent().parent().parent().remove()
  }
}

/**
 * check if the shopping cart exists in the database
 */
function checkCartExist () {
  const checkCartExistServiceUrl = page.data('check-cart-exist')
  const shoppingCartId = page.data('cartid-from-req')
  let cart = {}
  $.ajax({
    url: checkCartExistServiceUrl,
    type: 'POST',
    async: false,
    data: jQuery.param({ shoppingCartId: shoppingCartId }),
    success: function (data) {
      cart = data && data.cart
      if (data && !(data.cartExist)) {
        window.scrollTo(0, 0)
        window.location.reload()
      }
    } // end success
  })

  return cart
}

/**
 * apply capitalization to a string
 * @param {String} str string that must be modified
 * @param {Boolean} lower apply lower case to string
 * @returns {String} modified string
 */
function capitalize (str, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

/**
 * handles the selected payment method
 * @param {String} selectedMethod selected payment method
 */
function handleSelectedPaymentMethod (selectedMethod) {
  switch (selectedMethod) {
    case 'option1':
      $('#paymentMethodOnConfirmation').text('Kortbetaling')
      netaxeptRedirect()
      break
    case 'option2':
      $('#paymentMethodOnConfirmation').text('Faktura')
      salesforceInstallmentsInvoice.processPayment($, page.data('cartid-from-req'), true)
      break
    case 'option3':
      $('#paymentMethodOnConfirmation').text('Avbetaling')
      salesforceInstallmentsInvoice.processPayment($, page.data('cartid-from-req'), true)
      break
    case 'option4':
      $('#paymentMethodOnConfirmation').text('Annen betaler')
      otherPayer()
      break
  }
}

/**
 * sends the data for the payment to be completed using the credit card payment method to the NETS API.
 * It then redirects the user to the NETS payment portal
 */
function netaxeptRedirect () {
  const button = $('#btn-step3')
  const serviceUrl = page.data('netaxept-service')
  const shoppingCartId = page.data('cartid-from-req')
  const crmOrganisation = page.data('crm-organisation')

  setLoadButton(button)
  $.ajax({
    url: serviceUrl,
    type: 'POST',
    data: jQuery.param({ shoppingCartId: shoppingCartId, crmOrganisation: crmOrganisation }),
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    success: function (data) {
      if (data && data.success) {
        window.location.href = data.paymentRedirect
      } else {
        appendErrorMessage($('.step3 .messageError'))
        goToScrollTop($('#addedMessageError'))
        removeLoadButton(button)
      }
    }
  })
}

/**
 * adds an error message to the page if the payment method fails.
 * @param {*} htmlSectionToAppend section where the error message should be added
 */
function appendErrorMessage (htmlSectionToAppend) {
  const hasMessageErrorOnPage = $('#addedMessageError')
  if (!hasMessageErrorOnPage.length) {
    const responseCode = page.data('response-code')
    const message = responseCode === 'Cancel' ? `${strings.netsCancel}` : `${strings.netsError}`
    // const htmlSectionToAppend = $('.step3 .messageError')
    htmlSectionToAppend.prepend(
      `<div class='checkout__section' id='addedMessageError'>
        <div class="row resize-col">
          <div class="col-lg-10">
            <div class="box-orange">
              <p>${message}</p>
            </div>
          </div>
        </div>
      </div>`
    )
  }
}

/**
 * handles the "other payer" payment method.
 */
function otherPayer () {
  const button = $('#btn-step3')
  const otherPayerOption = $('.option4.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem')
  if (otherPayerOption.text()) {
    const selectedOption = otherPayerOption.text()
    const name = document.getElementById('otherPaymentName').value
    const email = document.getElementById('otherPaymentEmail').value

    if (selectedOption !== 'NAV') {
      const validEmail = validateEmail(email)
      if (!validEmail) {
        $('#otherPaymentEmail').css('color', 'red')
        goToScrollTop($('#otherPaymentEmail'), 250)
        return
      } else {
        $('#otherPaymentEmail').css('color', '')
      }
    }

    setLoadButton(button)
    if ((name && email)) {
      const checkoutGeneralService = page.data('checkout-courses-general-service')
      const shoppingCartId = page.data('cartid-from-req')
      if (selectedOption !== '- Valg for annen betaler -') {
        const studentRegisterSalesforceService = page.data('student-register-salesforce-service')

        const customerPaymentOption = getCustomerPaymentOption()

        const payerInfoByCustomer = {
          emailTo: email, name: name, otherPayerPaymentOption: selectedOption
        }
        $.ajax({
          url: studentRegisterSalesforceService,
          // async: async,
          data: { shoppingCartId: shoppingCartId, action: 'opportunity', paymentStep: true, customerPaymentOption: JSON.stringify(customerPaymentOption), payerInfoByCustomer: JSON.stringify(payerInfoByCustomer) },
          success: function (data) {
            if (data) {
              const result = data

              const orderId = result && result.orderId
              if (!orderId) {
                removeLoadButton(button)
                displaySalesforcePageError(button)
              } else {
                if (selectedOption === 'NAV') {
                  $.ajax({
                    url: checkoutGeneralService,
                    // type: 'POST',
                    data: { shoppingCartId: shoppingCartId, action: 'finishCart' },
                    // contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    success: function (data) {
                      if ((data.cart && data.cart.data && data.cart.data.status === 'checkout_complete')) {
                        gaDataLayer.processGADataLayerPurchase($, shoppingCartId, 'other')

                        goToConfirmationPage()
                        goToScrollTop($('.header.header--steps'))
                        $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
                        removeLoadButton(button)
                      }
                    }
                  })
                } else {
                  $.ajax({
                    url: checkoutGeneralService,
                    // type: 'POST',
                    data: { shoppingCartId: shoppingCartId, action: 'setCartToInProgress' },
                    // contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    success: function (data) {
                      if ((data.cart && data.cart.data && data.cart.data.status === 'checkout_in_progress')) {
                        gaDataLayer.processGADataLayerPurchase($, shoppingCartId, 'other')
                        goToConfirmationPage()
                        goToScrollTop($('.header.header--steps'))
                        $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
                      }
                      removeLoadButton(button)
                    }
                  })
                }
              }
            } else {
              removeLoadButton(button)
            }
          }
        })
      }
    } else {
      goToScrollTop($('#otherPaymentName').parent(), 250)
      removeLoadButton(button)
    }
  }
}

/**
 * if the payment operation is successful then the user is redirected to the payment screen.
 */
function goToConfirmationPage () {
  const cartIdFromReq = page.data('cartid-from-req')
  if (cartIdFromReq) {
    $('.step1').hide()
    $('.step2').hide()
    $('.step3').hide()
    $('.step4').show()

    $('.header-step1').removeClass('active done')
    $('.header-step1').addClass('disabled')
    $('.header-step2').removeClass('active done')
    $('.header-step2').addClass('disabled')
    $('.header-stepOptionalDocumentation').removeClass('active done')
    $('.header-stepOptionalDocumentation').addClass('disabled')
    $('.header-step3').removeClass('active done')
    $('.header-step3').addClass('disabled')
    $('.header-step4').removeClass('disabled')
    $('.header-step4').addClass('active')

    const customerInfo = page.data('get-cart-customer-info')

    if (customerInfo) {
      $.ajax({
        url: customerInfo,
        type: 'POST',
        data: { cartIdFromReq: cartIdFromReq },
        success: function (data) {
          if (data) {
            // append to section: Takk for din bestilling
            $('.checkout__text-info.withTitle.col-lg-8').append(`<ul>
            <li>
              I løpet av et par dager vil du motta bekreftelse på din
              påmelding samt bruker og passord til din e-post: <a
                 ${data.inputEmail ? `href=mailto: ${data.inputEmail}` : ''}
                 class="link-b-orange">${data.inputEmail ? `${data.inputEmail}` : ''}</a>
            </li>
          </ul>
          <h6>Er det noe du lurer på? Kontakt oss gjerne:</h6>

          <p>man-fre: 09-16</p>
          <p>67 58 88 00</p>`)

            // append to section: Student
            $('.checkout__section.student.customerInformation').append(`<div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputName">Navn</label>
              ${data.inputName && data.inputLastName ? `<div>${data.inputName} ${data.inputLastName}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputEmail">E-post </label>
            ${data.inputEmail ? `<div>${data.inputEmail}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputPhoneNumber">Telefonnummer</label>
              ${data.inputPhoneNumber ? `<div>${data.inputCountryCode || ''}${data.inputPhoneNumber}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputAddress">Gate/postboks</label>
              ${data.inputAddress ? `<div>${data.inputAddress}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputNumber">Sted</label>
              ${data.inputZipCode ? `<div>${data.inputZipCode}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputLand">Land</label>
              ${data.inputCountry && data.inputCountry.name ? `<div>${capitalize(data.inputCountry.name, true)}</div>` : ''}
            </div>
          </div>`)
          }
        } // end success
      })
    }
  }
}

/**
 * scrolls page to selected element
 * @param {*} element
 * @param {Number} h height the page should scroll to the element.
 */
function goToScrollTop (element, h = 20) {
  if (element.length) {
    $('html, body').animate(
      {
        scrollTop: element.offset().top - h
      }, 700)
  }
}

/**
 * adds CSS code for correct cursor display
 */
function setDefaultArrowPointer () {
  var css = document.createElement('style')
  css.type = 'text/css'
  css.innerHTML = '.header.header--steps .item.disabled {cursor: default!important;}'
  document.body.appendChild(css)
}

let skipCreateContactAndOpportunityPersonalInformation = 1 // prevents opportunity from being created/updated if checkout is coming back from NETS API error

/**
 * executes request to persist customer information in shopping cart
 * @param {Boolean} setHeaders variable to handle the behavior of the checkout header
 */
function createPersonalInformation (setHeaders = true) {
  updateCookie()
  const button = $('#btn-step2')

  const transactionId = page.data('transaction-id')
  const responseCode = page.data('response-code')
  const skipCreateContactAndOpportunity = !!(transactionId && responseCode)
  if (skipCreateContactAndOpportunity) {
    skipCreateContactAndOpportunityPersonalInformation = skipCreateContactAndOpportunityPersonalInformation - 1
  }

  var serviceUrl = page.data('service')
  var price = page.data('price')
  var formData = new FormData($('#formPersonalInformation')[0]) //eslint-disable-line
  var type = 'checkout-courses-form'
  const shoppingCartId = page.data('cartid-from-req')
  formData.append('type', type)
  // formData.append('programCourse', programCourse)
  formData.append('price', price)
  formData.append('shoppingCartId', shoppingCartId)
  formData.append('skipCreateContactAndOpportunity', skipCreateContactAndOpportunityPersonalInformation)

  const consent = $('#consent')
  if (consent.length && consent.hasClass('active')) {
    formData.set('consent', true)
  }

  if (consent.length && !consent.hasClass('active')) {
    formData.set('consent', false)
  }

  const validationResult = formsLib.handleValidation()
  const cart = checkCartExist()

  if (!cart || !cart.data || (!cart.data.items && !cart.data.courseItems)) {
    displaySalesforcePageError($('#btn-step2'))
    return
  }

  if (validationResult.hasInvalidFields) {
    const input = validationResult.invalidFields[0] && validationResult.invalidFields[0].input
    goToScrollTop($(input || '.checkout__section'), 200)
    return
  } else {
    formsLib.markInputsAsProcessed()
  }

  setLoadButton(button)

  $.ajax({
    url: serviceUrl,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: function (data) {
      if (data && data.status && data.status === 'success') {
        if ((data && data.salesforceCustomerId && data.salesforceOrderId)) {
          // createOrUpdateOpportunitySalesforce(shoppingCartId, 'POST')
          if (setHeaders) {
            documentation.setHeaderOptions($)
          }

          removeChildrenFromElement('div', '.removeIfUpdate')

          if (data.dataNode && data.dataNode.inputCountryCode && data.dataNode.inputPhoneNumber) {
            $('#inputPhoneNumber-step3').append(`<div class='removeIfUpdate'>${data.dataNode.inputCountryCode}${data.dataNode.inputPhoneNumber}</div>`)
          }

          $('#inputName-step3').append(data.dataNode && data.dataNode.inputName && data.dataNode.inputLastName ? `<div class='removeIfUpdate'>${data.dataNode.inputName} ${data.dataNode.inputLastName}</div>` : '')
          $('#inputSocialNumber-step3').append(data.dataNode && data.dataNode.inputSocialNumber ? `<div class='removeIfUpdate'>${data.dataNode.inputSocialNumber}</div>` : '')
          $('#inputEmail-step3').append(data.dataNode && data.dataNode.inputEmail ? `<div class='removeIfUpdate'>${data.dataNode.inputEmail}</div>` : '')
          $('#inputAddress-step3').append(data.dataNode && data.dataNode.inputAddress ? `<div class='removeIfUpdate'>${data.dataNode.inputAddress}</div>` : '')
          $('#inputCity-step3').append(data.dataNode && data.dataNode.inputZipCode && data.dataNode.inputCity ? `<div class='removeIfUpdate'>${data.dataNode.inputZipCode} ${data.dataNode.inputCity}</div>` : '')
          $('#inputCountry-step3').append(data.dataNode && data.dataNode.inputCountry && data.dataNode.inputCountry.name ? `<div class='removeIfUpdate'>${capitalize(data.dataNode.inputCountry.name, true)}</div>` : '')
          if (data && data.coupon && data.coupon.code) {
            $('#inputDiscountCoupon').val(data.coupon.code)
            $('#inputDiscountCoupon').addClass('valid')
          }

          goToScrollTop($('.header.header--steps'))

          gaDataLayer.processGADataLayerCheckoutSteps($, shoppingCartId, 3)
        } else {
          displaySalesforcePageError($('#btn-step2'))
        }
        removeLoadButton(button)
      } else {
        if (data && !data.cartExist) {
          window.scrollTo(0, 0)
          window.location.reload()
        }
        removeLoadButton(button)
      }
    }
  })
}

/**
 * displays salesforce checkout error screen
 * @param {*} elementToScroll element the page should scroll to if the user clicks retry.
 */
function displaySalesforcePageError (elementToScroll) {
  const cartIdFromReq = page.data('cartid-from-req')

  $('#checkoutError .fail__section .btn__try-again').on('click', null, function () {
    // callback()
    $('#checkoutError').hide()
    $('#checkoutCoursePageIDSalesforce').show()
    $('.header__wrapper .main__menu').show()
    goToScrollTop(elementToScroll)
  })

  if (cartIdFromReq) {
    const checkCartExistServiceUrl = page.data('check-cart-exist')
    $.ajax({
      url: checkCartExistServiceUrl,
      type: 'POST',
      async: false,
      data: jQuery.param({ shoppingCartId: cartIdFromReq }),
      success: function (data) {
        if (data && (data.cartExist)) {
          const cart = data.cart

          if (cart && cart.data && cart.data.customerInfo) {
            $('#checkoutError .contact__section .user-info .user-info-inputName').text(`${cart.data.customerInfo.inputName} ${cart.data.customerInfo.inputLastName}`)
            $('#checkoutError .contact__section .user-info .user-info-inputEmail').text(cart.data.customerInfo.inputEmail)
            $('#checkoutError .contact__section .user-info .user-info-inputPhoneNumber').text(cart.data.customerInfo.inputCountryCode || '' + cart.data.customerInfo.inputPhoneNumber)
            $('#checkoutError .contact__section .user-info .user-info-inputAddress').text(cart.data.customerInfo.inputAddress)
            $('#checkoutError .contact__section .user-info .user-info-inputCity').text(cart.data.customerInfo.inputCity)
            $('#checkoutError .contact__section .user-info .user-info-inputCountry').text(cart.data.customerInfo.inputCountry.name)

            $('#checkoutError').show()
            $('#checkoutCoursePageIDSalesforce').hide()
            $('.header__wrapper .main__menu').hide()
            goToScrollTop($('#checkoutError'))
          }
        }
      } // end success
    })
    // }
  }
}

/**
 * runs a request to the salesforce API to create or update an opportunity.
 * @param {String} shoppingCartId  the current shopping cart id
 * @param {Boolean} paymentStep indicates if the checkout is in the payment step
 * @param {Boolean} async indicates whether the request should be asynchronous or not
 * @returns {Object} result of request to salesforce API containing "orderId"
 */
function createOrUpdateOpportunitySalesforce (shoppingCartId, paymentStep, async = false) {
  let result
  const page = $('#checkoutCoursePageIDSalesforce')
  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
  $.ajax({
    url: studentRegisterSalesforceService,
    async: async,
    data: { shoppingCartId: shoppingCartId, action: 'opportunity', paymentStep: paymentStep },
    success: function (data) {
      if (data) {
        result = data
      }
    }
  })
  return result
}

/**
 * removes children from a given element
 * @param {*} tag parent tag
 * @param {*} tagClass child class that should be removed
 */
function removeChildrenFromElement (tag, tagClass) {
  $(tag).remove(tagClass)
}

/**
 * simulates the browser's back button
 */
function goBack () {
  if (window.history.length >= 2) {
    // if history is not empty, go back:
    window.history.back()
  }
  // else {
  //   // go home:
  //   window.history.replaceState(null, null, '/')
  //   window.location.replace('/')
  // }
}

/**
 * check if all form fields are filled in correctly
 * @param {*} formData data filled in the form
 * @returns {Boolean} true if there is an error
 */
function checkRequiredFields (formData) {
  const inputName = formData.get('inputName')
  const inputLastName = formData.get('inputLastName')
  const inputSocialNumber = formData.get('inputSocialNumber')
  const inputEmail = formData.get('inputEmail')
  const inputPhoneNumber = formData.get('inputPhoneNumber')
  const inputZipCode = formData.get('inputZipCode')
  const inputCity = formData.get('inputCity')
  const inputCountry = formData.get('inputCountry')
  const inputCountryCode = formData.get('inputCountryCode')
  const inputAddress = formData.get('inputAddress')
  const socialNumberBoxMessage = $('.social-number-message')
  const invalidFieldsToBeScrolledTo = []

  let backToTop = false

  if (inputName === '') {
    const inputNameElement = $('#inputName')
    inputNameElement.attr('placeholder', 'Påkrevd')
    inputNameElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputNameElement)
    backToTop = true
  } else {
    const inputNameElement = $('#inputName')
    inputNameElement.removeClass('invalid')
  }

  if (inputLastName === '') {
    const inputLastNameElement = $('#inputLastName')
    inputLastNameElement.attr('placeholder', 'Påkrevd')
    inputLastNameElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputLastNameElement)
    backToTop = true
  } else {
    const inputLastNameElement = $('#inputLastName')
    inputLastNameElement.removeClass('invalid')
  }

  if (inputCountryCode === '') {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.attr('placeholder', 'Påkrevd')
    inputCountryCodeElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputCountryCodeElement)
    backToTop = true
  } else {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.removeClass('invalid')
  }

  if (inputPhoneNumber === '') {
    const inputPhoneNumberElement = $('#inputPhoneNumber')
    inputPhoneNumberElement.attr('placeholder', 'Påkrevd')
    inputPhoneNumberElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputPhoneNumberElement)
    backToTop = true
  } else {
    const inputPhoneNumberElement = $('#inputPhoneNumber')
    inputPhoneNumberElement.removeClass('invalid')
  }

  if (inputEmail === '') {
    const inputEmailElement = $('.form-group.form-required input.inputEmail')
    inputEmailElement.attr('placeholder', 'Påkrevd')
    inputEmailElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputEmailElement)
    backToTop = true
  } else {
    const validEmail = validateEmail(inputEmail)
    if (!validEmail) {
      const inputEmailElement = $('.form-group.form-required input.inputEmail')
      if (inputEmailElement.hasClass('invalid')) {
        inputEmailElement.removeClass('invalid')
      }
      inputEmailElement.attr('placeholder', 'Påkrevd')
      inputEmailElement.addClass('invalidField')
      invalidFieldsToBeScrolledTo.push(inputEmailElement)
      backToTop = true
    } else {
      const inputEmailElement = $('.form-group.form-required input.inputEmail')
      if (inputEmailElement.hasClass('invalidField')) {
        inputEmailElement.removeClass('invalidField')
      }
    }
  }

  if (inputSocialNumber === '') {
    const inputSocialNumberElement = $('#inputSocialNumber')
    inputSocialNumberElement.attr('placeholder', 'Påkrevd')
    inputSocialNumberElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputSocialNumberElement)
    backToTop = true
  } else {
    const inputSocialNumberElement = $('#inputSocialNumber')

    const validator = fnrvalidator.fnr(inputSocialNumber)

    if (inputSocialNumber === '6073910828') {
      validator.status = 'valid'
    }

    if ((validator && validator.status && validator.status === 'invalid')) {
      inputSocialNumberElement.addClass('invalidField')
      socialNumberBoxMessage.show()
      invalidFieldsToBeScrolledTo.push(inputSocialNumberElement)
      backToTop = true
    } else {
      inputSocialNumberElement.removeClass('invalid')
      inputSocialNumberElement.removeClass('invalidField')
      socialNumberBoxMessage.hide()
    }
  }

  if (inputAddress === '') {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.attr('placeholder', 'Påkrevd')
    inputAddressElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputAddressElement)
    backToTop = true
  } else {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.removeClass('invalid')
  }

  if (inputZipCode === '') {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.attr('placeholder', 'Påkrevd')
    inputZipCodeElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputZipCodeElement)
    backToTop = true
  } else {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.removeClass('invalid')
  }

  if (inputCity === '') {
    const inputCityElement = $('#inputCity')
    inputCityElement.attr('placeholder', 'Påkrevd')
    inputCityElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputCityElement)
    backToTop = true
  } else {
    const inputCityElement = $('#inputCity')
    inputCityElement.removeClass('invalid')
  }

  if (!inputCountry) {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.addClass('invalidFieldSelect')
    invalidFieldsToBeScrolledTo.push(inputCountryElement)
    backToTop = true
  } else {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.removeClass('invalidFieldSelect')
  }

  if (backToTop) {
    if (invalidFieldsToBeScrolledTo.length) {
      goToScrollTop(invalidFieldsToBeScrolledTo[0], 200)
    } else {
      goToScrollTop($('.checkout__section'), 150)
    }
  }

  return backToTop
}

/**
 * check if the format of an email is valid
 * @param {String} email
 * @returns {Boolean}
 */
function validateEmail (email) {
  const re = /^[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:[._-][a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*@[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:-[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,}(?:\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

function termsWereChecked () {
  const checkbox = $('.checkout__bottom__content input[type=checkbox]:visible')
  if (!checkbox.is(':checked')) {
    checkbox.parent().addClass('invalid')
    return false
  }
  checkbox.parent().removeClass('invalid')
  return true
}

function addEvent (el, eventType, handler) {
  if (el.addEventListener) { // DOM Level 2 browsers
    el.addEventListener(eventType, handler, false)
  } else if (el.attachEvent) { // IE <= 8
    el.attachEvent('on' + eventType, handler)
  } else { // ancient browsers
    el['on' + eventType] = handler
  }
}

function HistoryButtonOverride (BackButtonPressed, ForwardButtonPressed) {
  const history = window.history
  const location = window.location

  const Reset = function () {
    if (history.state == null) { return }
    if (history.state.customHistoryStage === 1) { history.forward() } else if (history.state.customHistoryStage === 3) { history.back() }
  }
  const BuildURLWithHash = function () {
    // The URLs of our 3 history states must have hash strings in them so that back and forward events never cause a page reload.
    return location.origin + location.pathname + location.search + (location.hash && location.hash.length > 1 ? location.hash : '#')
  }
  if (history.state == null) {
    // This is the first page load. Inject new history states to help identify back/forward button presses.
    const initialHistoryLength = history.length
    history.replaceState({ customHistoryStage: 1, initialHistoryLength: initialHistoryLength }, '', BuildURLWithHash())
    history.pushState({ customHistoryStage: 2, initialHistoryLength: initialHistoryLength }, '', BuildURLWithHash())
    history.pushState({ customHistoryStage: 3, initialHistoryLength: initialHistoryLength }, '', BuildURLWithHash())
    history.back()
  } else if (history.state.customHistoryStage === 1) {
    history.forward()
  } else if (history.state.customHistoryStage === 3) {
    history.back()
  }

  addEvent(window, 'popstate', function (e) {
    // Called when history navigation occurs.
    if (history.state == null) { return }
    if (history.state.customHistoryStage === 1) {
      if (typeof BackButtonPressed === 'function' && BackButtonPressed()) {
        Reset()
        return
      }
      if (history.state.initialHistoryLength > 1) history.back() // There is back-history to go to.
      else { history.forward() } // No back-history to go to, so undo the back operation.
    } else if (history.state.customHistoryStage === 3) {
      if (typeof ForwardButtonPressed === 'function' && ForwardButtonPressed()) {
        Reset()
        return
      }
      if (history.length > history.state.initialHistoryLength + 2) history.forward() // There is forward-history to go to.
      else { history.back() } // No forward-history to go to, so undo the forward operation.
    }
  })
}
