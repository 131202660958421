import $ from 'jquery'
import '../external/sticky-sidebar.js'
import { parseChars } from './util'

$(document).ready(function () {
  if ($('.studieVelgerPageData').length) {
    // Show btnFloatWrapper
    var btnFloatWrapper = $('.btnFloatWrapper')
    btnFloatWrapper.addClass('active')

    var filterStudievelger = $('.filter-studies, .filter-studies__btn-bar')
    var filterStudievelgerBtn = $('.btnFloatWrapper__btn.filter, .filter-studies__close')
    var filterStudievelgerBtnMobile = $('.filter-studies__btn-bar')

    filterStudievelgerBtn.on('click', null, function () {
      filterStudievelger.toggleClass('active')
    })

    filterStudievelgerBtnMobile.on('click', null, function () {
      filterStudievelger.toggleClass('active')
      goToScrollTop($('.filters-bar__results-number'))
    })

    setDefaultArrowPointer()

    const arrayOfFilters = $('.studieVelgerPageData').data('array-filters')
    arrayOfFilters.forEach(filter => {
      const [filterKey] = filter.split(':')
      $(`#${filterKey}`).toggleClass('active')
    })

    const filters = $('.filter-studies .accordion--basic .card .card-body input')

    filters.on('click', null, function () {
      $('.filters-bar__tags .hide-tag').removeClass('hide-tag')
      // event.preventDefault()
      $(this).toggleClass('active')

      hideShowallButton()

      buildTagFilters($(this))

      removeTags()

      updateProgramsOnPage()
    })

    removeTags()

    openActiveSections()

    // Add arrow accordion filter
    var linkArrow = $('.accordion--basic .card-header button span')
    addLinkArrow(linkArrow)

    // Add arrow accordion filter

    enableProgramLink()

    hideShowallButton()

    processGADataLayerProductClick()

    sideMenuSticky()
  }

  if ($('.program-list').length) {
    handleNewFilters()
  }
})

function handleNewFilters () {
  const arrayOfFilters = $('.program-list').data('array-filters')

  // Fill the filter option with the value from query param
  const filtersMap = new Map()
  arrayOfFilters.filter(([filterKey, filterValue]) => filterKey !== 's').forEach(filter => {
    const [, filterValue] = filter.split(':')
    const filterElement = $(`#${filterValue}`)
    const filterLabel = filterElement.text()
    const filterGroupParent = filterElement.data('group-parent')

    let values = [filterLabel]
    if (filtersMap.has(filterGroupParent)) {
      values = filtersMap.get(filterGroupParent)
      values.push(filterLabel)
    }
    filtersMap.set(filterGroupParent, values)
  })

  for (const [key, value] of filtersMap) {
    const groupParent = $(`#${key}`)
    groupParent.find('.courseSelect__btn button p').text(value.join(', '))
  }

  $('.clear-input').on('click', function () {
    const searchParams = new URLSearchParams(window.location.search)
    $(this).prev('input').val('')

    searchParams.delete('s')

    performQuery({ searchParams })
  })

  $('.filter-search input').on('keyup', function () {
    const searchParams = new URLSearchParams(window.location.search)

    if (!$(this).val()) {
      searchParams.delete('s')
    } else if (searchParams.has('s')) {
      searchParams.set('s', $(this).val())
    } else {
      searchParams.append('s', $(this).val())
    }

    performQuery({ searchParams })
  })

  $('.courseSelect__options .items li a').on('click', function () {
    const value = $(this).attr('id')
    const group = $(this).attr('data-group-parent') + '[]'

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has(group)) {
      searchParams.set(group, value)
    }

    if (!searchParams.has(group) && value) {
      searchParams.append(group, value)
    }

    if (!value) {
      searchParams.delete(group)
    }

    performQuery({ searchParams })
  })

  $('.program-list__list .link-list a').on('click', function () {
    const programId = $(this).attr('id')
    performGADataLayerProductClick({ programId })
  })

  const performQuery = ({ searchParams }) => {
    const url = $('.program-list').data('url')

    const queryParams = searchParams.toString()
    const browserUrl = url + decodeURIComponent((queryParams ? `?${queryParams}` : ''))
    window.history.pushState('object or string', 'Title', browserUrl)

    const allowedQueryParams = ['fagomrade[]', 'utdanningsniva[]', 's']
    const searchParamsEntries = searchParams.entries()
    for (const [key] of searchParamsEntries) {
      if (!allowedQueryParams.includes(key)) {
        searchParams.delete(key)
      }
    }

    if (!searchParams.toString()) {
      $('.program-list__list li.list-item.d-none').removeClass('d-none')
    } else {
      const searchParamFilters = []
      const searchParamFiltersMap = new Map()
      const searchTerm = searchParams.get('s')
      searchParams.delete('s')

      for (const [key, value] of searchParams.entries()) {
        if (allowedQueryParams.includes(key)) {
          const sanitizedKey = key.replace('[]', '')
          searchParamFilters.push(`${sanitizedKey}:${value}`)
          let count = 1
          if (searchParamFiltersMap.has(sanitizedKey)) {
            count = searchParamFiltersMap.get(sanitizedKey) + 1
          }
          searchParamFiltersMap.set(sanitizedKey, count)
        }
      }

      /**
       * The logic implemented to check if the filters are matching is as the following:
       * An Map object is created with the amount of filters from the query param as { fagomrade: 1, utdanningsniva: 2 } for example.
       * This means that we have one filter from fagomrade and two from uutdanningsniva.
       * To be shown, a program should have at least one of each filter, since the condition checked is OR between filters from the same group and AND between different groups.
       * Fo each program, I create a similar object as above and compare the amount of filters
       */
      $('.program-list__list li.list-item').each(function () {
        const filters = JSON.parse($(this).attr('data-filters'))
        const filtersMatchMap = new Map()

        if (searchTerm && !parseChars($(this).find('h2').text()).toLowerCase().includes(parseChars(searchTerm.toLowerCase()))) {
          $(this).addClass('d-none')
          return
        }

        searchParamFilters.forEach(searchFilter => {
          if (filters.join(',').split(',').indexOf(searchFilter) >= 0) {
            const [key] = searchFilter.split(':')
            let count = 1
            if (filtersMatchMap.has(key)) {
              count = filtersMatchMap.get(key) + 1
            }
            filtersMatchMap.set(key, count)
          }
        })

        let shouldShow = true
        for (const [key] of searchParamFiltersMap) {
          if (!filtersMatchMap.has(key)) shouldShow = false
        }

        if (!shouldShow) {
          $(this).addClass('d-none')
          return
        }

        $(this).removeClass('d-none')
      })
    }

    // Update total programs
    const totalProgramsVisible = $('.program-list__list li.list-item').not('.d-none').length
    $('.results-number p').text(`Viser ${totalProgramsVisible} studier`)
  }

  const performGADataLayerProductClick = ({ programId }) => {
    const gaDataLayerService = $('.program-list').data('ga-data-layer-service')

    $.ajax({
      url: gaDataLayerService,
      data: { programId, action: 'Product click' },
      success: function (data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list: 'Finn ditt retning' },
              products: data.products
            }
          }
        })
      }
    })
  }

  // Check URL filters and hide programs if necessary
  (function () {
    const searchParams = new URLSearchParams(window.location.search)
    performQuery({ searchParams })
  })()
}

function sideMenuSticky () {
  var sidebar = new StickySidebar('.contentScroll > .container > .row > .leftFilterBar', { // eslint-disable-line
    topSpacing: 150,
    minWidth: 800,
    bottomSpacing: 0,
    resizeSensor: false,
    containerSelector: '.contentScroll',
    innerWrapperSelector: '.contentScroll > .container > .row > .leftFilterBar'
  })

  $('.header > .inner-wrapper-sticky').on('classAdded', function () {
    sidebar.options.topSpacing = 0
    sidebar.updateSticky()
  })

  $('.header > .inner-wrapper-sticky').on('classRemoved', function () {
    sidebar.options.topSpacing = 150
    sidebar.updateSticky()
  })
}

function processGADataLayerProductClick () {
  $('.card .card-header .btnTitle a').on('click', null, function () {
    const programId = $(this).parent().parent().parent().parent().attr('id').split('heading-')[1]

    const gaDataLayerService = $('.studieVelgerPageData').data('ga-data-layer-service')

    $.ajax({
      url: gaDataLayerService,
      data: { programId: programId, action: 'Product click' },
      success: function (data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list: 'Finn ditt retning' },
              products: data.products
            }
          }
        })
      }
    })
  })
}

function enableProgramLink () {
  // Enable Program link
  const linkProgram = $('.accordion--purple .card-header h2 button a')
  linkProgram.on('click', null, function (event) { event.stopImmediatePropagation() })
  // Enable Program link
}

function setDefaultArrowPointer () {
  var css = document.createElement('style')
  css.type = 'text/css'
  css.innerHTML = '.filters-bar__tags .empty-tag {cursor: default!important;}'
  document.body.appendChild(css)
}

function addLinkArrow (el) {
  el.html(function () {
    var text = $(this).text().trim().split(' ')
    var last = text.pop()
    var lastWord = last.substring(0, (last.length - 1))
    var lastChar = last.split('')
    var char = lastChar.pop()
    return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
  })
}

function hideShowallButton () {
  if ($('.filter-studies .accordion--basic .card .card-body .active').length === 0) {
    $('.filters-bar__tags .empty-tag, .filters-bar__tags .hide-tag').show()
    // console.log($('.filter-studies .accordion--basic .card .card-body .active').length)
  } else {
    $('.filters-bar__tags .empty-tag, .filters-bar__tags .hide-tag').hide()
    // console.log($('.filter-studies .accordion--basic .card .card-body .active').length)
  }
}

function goToScrollTop (element) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top - 150
    }, 700)
}

function buildTagFilters (filterTag) {
  // console.log(filterTag)
  if (filterTag.hasClass('active')) {
    const filterBarSection = $('.page .filters-bar__tags')
    const html = `<button class="remove-tag" id="${'tag-' + filterTag.attr('id')}">${filterTag.attr('name')}</button>`
    filterBarSection.append(html)
    // console.log(html)
  } else {
    // console.log(filterTag.attr('id'))
    const filterBarElement = 'tag-' + filterTag.attr('id')
    // console.log(filterBarElement)
    $(`#${filterBarElement}`).remove()
  }
}

function removeTags () {
  $('.filters-bar__tags .remove-tag').on('click', null, function () {
    $('.filters-bar__tags .hide-tag').removeClass('hide-tag')
    // console.log($(this))
    const id = $(this).attr('id')
    const tagId = id.split('tag-')[1]

    $(`#${tagId}`).removeClass('active')

    $(`#${id}`).remove()

    updateProgramsOnPage()

    hideShowallButton()
  })
}

function buildParams () {
  const arrayOfFiltersActive = $('.filter-studies .accordion--basic .card .card-body .active')

  let params = ''
  for (let i = 0; i < arrayOfFiltersActive.length; i++) {
    const id = arrayOfFiltersActive[i].id
    const group = $(`#${id}`).data('group')
    if (i === 0) {
      params += '?'
    }

    params += `${group}[]=${id}`

    if (i < arrayOfFiltersActive.length - 1) {
      params += '&'
    }
  }

  return params
}

function openActiveSections () {
  const arrayOfFiltersActive = $('.filter-studies .accordion--basic .card .card-body .active')

  for (let i = 0; i < arrayOfFiltersActive.length; i++) {
    // console.log(arrayOfFiltersActive[i].id)
    const id = arrayOfFiltersActive[i].id
    // console.log(id)
    if (id) {
      // console.log()
      if (!$(`#${id}`).parent().parent().hasClass('show')) {
        $(`#${id}`).parent().parent().addClass('show')
        const idSection = $(`#${id}`).parent().parent().attr('data-parent')
        $(`${idSection} .card-header button`).attr('aria-expanded', 'true')
        // console.log(idSection)
      }
    }
  }
}

function updateProgramsOnPage () {
  const params = buildParams()
  const url = $('.studieVelgerPageData').data('service') + params
  // console.log(url)

  const browserUrl = $('.studieVelgerPageData').data('url') + params
  window.history.pushState('object or string', 'Title', browserUrl)

  // console.log(browserUrl)

  $.ajax({
    url: url,
    success: function (data) {
      // remove and then add new elements
      if (data) {
        const cardsParent = $('#accordionExample2')
        $('.accordion.accordion--purple .card').remove()
        // console.log(cardsParent)

        data.program && data.program.length && data.program.forEach((program) => {
          const html = buildCardProgram(program)
          cardsParent.append(html)
        })

        updateTotalPrograms(data.total)

        enableProgramLink()

        window.dataLayer.forEach(item => { // GA - dataLayer
          if (item && item.ecommerce) {
            item.ecommerce.impressions = (data && data.programToDataLayer) || []
          }
        })
      }

      goToScrollTop($('.filters-bar .filters-bar__results-number'))
    }
  })
}

function buildCardProgram (program) {
  return `<div class="card">
  <div class="card-header" id='heading-${program.id}'>
    <h2>
      <button class="collapsed" type="button" data-toggle="collapse" data-target='#collapse-${program.id}' aria-expanded="false" aria-controls='collapse-${program.id}'>

      ${program.title && program.link ? `<span class="btnTitle"><a href="${program.link && program.link.href}">${program.title}</a></span>` : ''}
        <div class="card-header__tags">
        ${program.informationDisplayField && program.informationDisplayField.education_level ? `<span>${program.informationDisplayField.education_level}</span>` : ''}
        ${program.informationDisplayField && program.informationDisplayField.conduct && program.informationDisplayField.conduct !== 'false' ? `<span>${program.informationDisplayField.conduct}</span>` : ''}
        ${program.informationDisplayField && program.informationDisplayField.financing && program.informationDisplayField.financing !== 'false' ? `<span>${program.informationDisplayField.financing}</span>` : ''}
        </div>
      </button>
    </h2>
  </div>
  <div id='collapse-${program.id}' class="collapse" aria-labelledby='heading-${program.id}' data-parent="#accordionExample2">
      <div class="card-body">
      ${program.ingress ? `<p>${program.ingress}</p>` : ''}
      </div>
  </div>
</div>`
}

function updateTotalPrograms (total) {
  const filterBar = $('.filters-bar')
  $('.filters-bar .filters-bar__results-number').remove()

  const html = `<div class="filters-bar__results-number">Viser ${total} studier</div>`
  filterBar.append(html)

  const filterButton = $('.filter-studies__btn-bar')
  $('.btn.btn-primary.btn-filter').remove()

  const htmlButton = `<button class="btn btn-primary btn-filter">Vis <span>${total}</span> studier</button>`
  filterButton.append(htmlButton)
}
