import $ from 'jquery'
import '../external/jquery-cookie.js'

$(document).ready(function () {
  const HISTORY_LENGTH = 10
  // Search
  var activeSearch = $('.main__menu--desktop .item.search, .main__menu--mobile .item.search, .dropDownMenu__desktop .item.search, .search-bar-wrapper .btn-close')
  var searchWrapper = $('.search-bar-wrapper')
  var inputSearch = $('.search-bar-wrapper__header input')
  var inputSearchResult = $('.search-result-wrapper__header input')
  var widthWindow = $(window).width()
  var body = $('body')

  var searchSuggestions = $('.search-bar-wrapper').data('search-suggestions').replace('[', '').replace(']', '').split(',')
  const buttonCloseSearch = $('.search-bar-wrapper .search-bar-wrapper__header .btn.btn-close')
  const buttonCloseSearchResult = $('.search-result-wrapper .search-result-wrapper__header .btn.btn-close')
  let searchTerm = $('.search-result-wrapper').data('search-term')

  if (searchTerm) {
    $('.search-result-wrapper .search-result-wrapper__result').fadeIn()
  } else {
    $('.search-result-wrapper .search-result-wrapper__result').fadeOut()
  }

  activeSearch.click(function (el) {
    $('.main__menu--desktop .item.search, .main__menu--mobile .item.search, .search-bar-wrapper form, .dropDownMenu__desktop .item.search').trigger('reset')
    $('.main__menu--desktop .item.search, .main__menu--mobile .item.search, .search-bar-wrapper form input, .dropDownMenu__desktop .item.search').focus()
    searchWrapper.toggleClass('active')
    body.toggleClass('bodyBlock')
  })

  $('.dropDownMenu__desktop .item.search').click(function () {
    body.addClass('bodyBlock')
  })

  buttonCloseSearch.click(function (el) {
    $('.search-bar-wrapper__suggestion').empty()
    if ($('.dropDownMenu').hasClass('active')) {
      $('.importantMessage').toggleClass('toHide')
      $('.dropDownMenu').removeClass('active')
    }
    body.removeClass('bodyBlock')
  })

  buttonCloseSearchResult.click(function (el) {
    $('.search-result-wrapper .search-result-wrapper__header form').trigger('reset')
    // const searchTerm = $('.search-result-wrapper').data('search-term')
    if (searchTerm) {
      $('.search-result-wrapper').data('search-term', '')
      $('.search-result-wrapper .search-result-wrapper__header form input').attr('value', '')
      const browserUrl = $('.search-result-wrapper').data('url')
      window.history.pushState('object or string', 'Title', browserUrl)

      $('.search-result-wrapper .search-result-wrapper__result').fadeOut()
    }
  })

  buildPlaceholder(widthWindow, inputSearch)
  buildPlaceholder(widthWindow, inputSearchResult)

  function buildPlaceholder (width, searchItem) {
    var placeholderDesktop = searchItem.attr('placeholder')
    var placeholderMobile = searchItem.data('placeholdermobile')
    if (width <= 992) {
      searchItem.attr('placeholder', placeholderMobile)
    } else {
      searchItem.attr('placeholder', placeholderDesktop)
    }
  }
  // Search

  // Search Result
  // Button Wrapper
  var btnWrapper = document.getElementsByClassName('buttonWrapper')
  var btn = $('.buttonWrapper .btn')

  btn.on('dragstart', function () {
    return false
  })

  for (var i = 0; i < btnWrapper.length; i++) {
    const _thisSlider = btnWrapper[i]
    let isDown = false
    let startX
    let scrollLeft

    _thisSlider.addEventListener('mousedown', (e) => {
      isDown = true
      _thisSlider.classList.add('active')
      startX = e.pageX - _thisSlider.offsetLeft
      scrollLeft = _thisSlider.scrollLeft
    })

    _thisSlider.addEventListener('mouseleave', () => {
      isDown = false
      _thisSlider.classList.remove('active')
      btn.removeClass('disableBtn')
    })

    _thisSlider.addEventListener('mouseup', () => {
      isDown = false
      _thisSlider.classList.remove('active')
      btn.removeClass('disableBtn')
    })

    _thisSlider.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - _thisSlider.offsetLeft
      const walk = (x - startX) * 1
      _thisSlider.scrollLeft = scrollLeft - walk
      btn.addClass('disableBtn')
    })
  }
  // Button Wrapper

  const hasMenuActive = $('.search-result-wrapper .search-result-wrapper__result .content button.active')
  if (hasMenuActive.length === 0) {
    $('.search-result-wrapper .search-result-wrapper__result .content button').first().addClass('active')
  }

  const hasBodyActive = $('.search-result-wrapper .search-result-wrapper__result .result-list.active')
  if (hasBodyActive.length === 0) {
    $('.search-result-wrapper .search-result-wrapper__result .result-list').first().addClass('active')
  }

  // Change Result
  btn.click(function () {
    btn.removeClass('active')
    $(this).addClass('active')
    var thisButton = $(this).attr('data-result')
    $('.result-list').removeClass('active')
    $('.result-list[data-result=' + thisButton + ']').addClass('active')

    const contentType = $('.search-result-wrapper .search-result-wrapper__result .content button.active').data('content-type')

    const contentTypeToUrl = codeProgramName(contentType)

    const searchTerm = $('.search-result-wrapper').data('search-term') || ''

    const page = parseInt($('.search-result-wrapper').data(`page-${contentType}`))

    const browserUrl = $('.search-result-wrapper').data('url') + '?s=' + searchTerm + '&c=' + contentTypeToUrl + '&page=' + page

    // console.log(browserUrl)
    window.history.pushState('object or string', 'Title', browserUrl)
  })

  // Change Result

  // Add bullet
  var bannerCtaArrow = $(".result-list[data-result='program'] .item .info div")
  addLinkArrow(bannerCtaArrow)

  const btnShowMoreProgram = $('.search-result-wrapper .result-list .showMoreWrapper.program')
  const btnShowMoreInformation = $('.search-result-wrapper .result-list .showMoreWrapper.information')
  const btnShowMoreBlogs = $('.search-result-wrapper .result-list .showMoreWrapper.blogs')

  // console.log(btnShowMore)
  btnShowMoreProgram.click(function (event) {
    event.preventDefault()
    // console.log($(this))
    appendItem('program', $(this))
  })

  btnShowMoreInformation.click(function (event) {
    event.preventDefault()
    // console.log($(this))
    appendItem('information', $(this))
  })

  btnShowMoreBlogs.click(function (event) {
    event.preventDefault()
    appendItem('blogs', $(this))
  })

  let lastSearches = $.cookie('lastSearches')
  var isSuggestionWord = false

  searchTerm = removeSpecialCharacters(searchTerm)

  searchSuggestions.forEach(suggestion => {
    if (suggestion.trim() === searchTerm) {
      isSuggestionWord = true
    }
  })

  if (isSuggestionWord === false) {
    if (lastSearches === undefined) {
      if (searchTerm) {
        lastSearches = [searchTerm]
        $.cookie('lastSearches', lastSearches.join(), { expires: 365, path: '/' })
      }
    } else {
      lastSearches = lastSearches.split(',')
      lastSearches.unshift(searchTerm)
      lastSearches = lastSearches.filter(function (item, pos) {
        return lastSearches.indexOf(item) === pos
      }).filter(function (el) { return el })
      if (lastSearches.length >= HISTORY_LENGTH + 1) {
        lastSearches.pop()
      }
      $.cookie('lastSearches', lastSearches, { expires: 365, path: '/' })
    }
  }
})

function removeSpecialCharacters (searchTerm) {
  return searchTerm ? searchTerm.toString().replace(',', ' ').replace(/[`~!#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, '').trim() : searchTerm
}

function codeProgramName (programName) {
  const matrix = {
    program: 'studier',
    information: 'informasjon',
    blogs: 'bloggen'
  }

  return matrix[programName] || undefined
}

function addLinkArrow (el) {
  el.html(function () {
    var text = $(this).text().trim().split(' ')
    var last = text.pop()
    var lastWord = last.substring(0, (last.length - 1))
    var lastChar = last.split('')
    var char = lastChar.pop()
    return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
  })
}
// Add bullet
// Search Result

function appendItem (contentType, contextShowMoreButton) {
  const service = $('.search-result-wrapper').data('service')
  const searchTerm = $('.search-result-wrapper').data('search-term') || ''

  // page management
  let page = parseInt($('.search-result-wrapper').data(`page-${contentType}`)) + 1
  $('.search-result-wrapper').data(`page-${contentType}`, page)
  page = parseInt($('.search-result-wrapper').data(`page-${contentType}`))

  const url = service + '?s=' + searchTerm + '&c=' + contentType + '&page=' + page

  const contentTypeToUrl = codeProgramName(contentType)

  const browserUrl = $('.search-result-wrapper').data('url') + '?s=' + searchTerm + '&c=' + contentTypeToUrl + '&page=' + page

  window.history.pushState('object or string', 'Title', browserUrl)

  // console.log(browserUrl)

  // console.log(service)
  // console.log(searchTerm)
  // console.log(page)

  $.ajax({
    url: url,
    success: function (data) {
      // Add new elements
      if (data) {
        // console.log(data)

        data.length && data.forEach(contentTypeObj => {
          if (contentTypeObj.id === contentType) {
            // console.log(contentTypeObj.showMoreButton)

            // showMoreButton
            if (!contentTypeObj.showMoreButton) {
              contextShowMoreButton.fadeOut()
            }

            contentTypeObj.list.forEach(obj => {
              const x = buildElement(contentType, obj)
              // console.log(x)
              $(`#${contentType} .content.insert`).append(x)
              if (contentType === 'program') {
                var bannerCtaArrow = $(".result-list[data-result='program'] .item .info div")
                addLinkArrow(bannerCtaArrow)
              }
            })
          }
        })
      }
    }
  })
}

function buildElement (contentType, obj) {
  switch (contentType) {
    case 'program':
      return buildProgramItem(obj)
    case 'information':
      return buildInformationItem(obj)
    case 'blogs':
      return buildBlogsItem(obj)
  }
}

function buildProgramItem (obj) {
  return `<div class="item">
  <div class="link">
      <span><a href="${obj && obj.link && obj.link.href}">${obj && obj.title}</a></span>
  </div>
  <div class="info">
      ${obj.informationDisplayField.education_level ? `<div>${obj.informationDisplayField.education_level}</div>` : ''}
      ${obj.informationDisplayField.conduct && obj.informationDisplayField.conduct !== 'false' ? `<div>${obj.informationDisplayField.conduct}</div>` : ''}
      ${obj.informationDisplayField.financing && obj.informationDisplayField.financing !== 'false' ? `<div>${obj.informationDisplayField.financing}</div>` : ''}
  </div>
</div>`
}

function buildInformationItem (obj) {
  const image = obj.image || obj.image_sub
  return `<div class="item">

      ${image ? '<div class="imgWrapper">' : ''}
        <a href="${obj && obj.link && obj.link.href}">
        ${image ? `<img src='${image.link}' alt='${image.alt}' title='${image.title}'/>` : ''}\
        </a>
        ${image ? '</div>' : ''}
        <div class="info">
            <div class="title">
              <a href="${obj && obj.link && obj.link.href}">
                <h3>${(obj && obj.title) || ''}</h3>
              </a>
            </div>
            <div class="textWrapper">
              ${(obj && obj.ingress) || ''}
            </div>
        </div>

  </div>`
}

function buildBlogsItem (obj) {
  return ` <div class="item">
    <div class="content">

      ${obj.image ? '<div class="imgWrapper">' : ''}
      <a href="${obj && obj.link && obj.link.href}">
      ${obj.image ? `<img src='${obj.image.link}' alt='${obj.image.alt}' title='${obj.image.title}'/>` : ''}\
      </a>
      ${obj.image ? '</div>' : ''}
        <div class="info">
            <span class="date">${(obj && obj.date) || ''}</span>
            <a href="${obj && obj.link && obj.link.href}">
            <h3 class="title">${(obj && obj.title) || ''}</h3>
            </a>
        </div>

    </div>
  </div>`
}
