
/**
 * executes a request to the Google Analytics API to inform the removal of a course from the shopping cart
 * @param {*} $ jQuery
 * @param {String} programId the current program id
 * @param {String} cartId the current shopping cart id
 */

function processGADataLayerRemoveFromCart ($, programId, cartId) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const gaDataLayerService = page.data('ga-data-layer-service')

  $.ajax({
    url: gaDataLayerService,
    data: { programId: programId, action: 'removeFromCart', cartId: cartId },
    success: function (data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: data.products
          }
        }
      })
    }
  })
}

/**
 * executes a request to the Google Analytics API to inform that the customer has advanced to the next step of the checkout
 * @param {*} $ jQuery
 * @param {String} cartId the current shopping cart id
 * @param {String} step the current checkout step
 */
function processGADataLayerCheckoutSteps ($, cartId, step) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const gaDataLayerService = page.data('ga-data-layer-service')

  $.ajax({
    url: gaDataLayerService,
    data: { cartId: cartId, action: 'checkout' },
    success: function (data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'checkout',
        applicationflow: 'threestep',
        ecommerce: {
          checkout: {
            actionField: { step: step },
            products: data.products
          }
        }
      })
    }
  })
}

/**
 * executes a request to the Google Analytics API to inform that a purchase has been completed
 * @param {*} $ jQUery
 * @param {Stritn} cartId the current shopping cart id
 * @param {Boolean} paymentMethod informs if the current step is the payment step
 * @param {Boolean} threestep informs if it is the normal flow of the checkout or if it is the checkout of the otherPayer form
 */
function processGADataLayerPurchase ($, cartId, paymentMethod, threestep = true) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const gaDataLayerService = page.data('ga-data-layer-service')
  // "creditcard", “downpayment”, “invoice” or “other”.
  $.ajax({
    url: gaDataLayerService,
    data: { cartId: cartId, action: 'purchase' },
    success: function (data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'purchase',
        applicationflow: threestep ? 'threestep' : 'singleflow',
        ecommerce: {
          purchase: {
            actionField: {
              id: data.orderNumber,
              revenue: data.price,
              affiliation: paymentMethod
            },
            products: data.products
          }
        }
      })
    }
  })
}

module.exports = {
  processGADataLayerRemoveFromCart,
  processGADataLayerCheckoutSteps,
  processGADataLayerPurchase
}
