import $ from 'jquery'

$(document).ready(function () {
  var selectCourse = $('.courseSelect')
  if (selectCourse.length) {
    $.each(selectCourse, function (index, value) {
      var thisSelect = $(this)
      var selectCourseBtn = thisSelect.find('.courseSelect__btn button')
      var selectCourseOptions = thisSelect.find('.courseSelect__options')
      var optionButton = thisSelect.find('.courseSelect__options .items li a')

      $(window).on('click', function () {
        closeSelectCourse()
      })
      selectCourseBtn.on('click', function (event) {
        event.stopPropagation()

        $('.courseSelect__options').not(selectCourseOptions).slideUp(300)
        selectCourseOptions.slideToggle(300)

        $('.courseSelect__btn button').not(selectCourseBtn).removeClass('active')
        selectCourseBtn.toggleClass('active')

        $('.courseSelect').not(thisSelect).removeClass('active')
        thisSelect.toggleClass('active')
      })
      selectCourseOptions.on('click', function (event) {
        event.stopPropagation()
      })

      // change button text and close dropdown
      if (thisSelect.hasClass('canSelect')) {
        optionButton.on('click', function () {
          selectCourseBtn.find('p').text($(this).text())
          closeSelectCourse()
        })
      }
    })
  }

  // Close dropdowns
  function closeSelectCourse () {
    $('.courseSelect__options').slideUp(300)
    $('.courseSelect__btn button').removeClass('active')
    selectCourse.removeClass('active')
  }
})
