import $ from 'jquery' //eslint-disable-line
import jQuery from 'jquery' //eslint-disable-line
import 'bootstrap/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import salesforceInstallmentsInvoice from '../salesforce-installments-invoice'
// import gaDataLayer from '../ga-data-layer'
import fnrvalidator from '../../../external/fnrvalidator'
import coupon from '../discount-coupon'
import * as formsLib from '../../forms-validation'

const page = $('#checkoutOtherPayerSalesforce')
const strings = page.data('strings')

$(document).ready(function () {
  const checkoutOtherPayer = $('#checkoutOtherPayerSalesforce')
  if (checkoutOtherPayer.length) {
    // manipulate navigation bar
    $('#header-step1').remove()
    $('#header-step2').remove()
    $('#header-stepOptionalDocumentation').remove()
    $('#header-step3').removeClass('disabled')
    $('#header-step3').addClass('active')
    // manipulate navigation bar

    // frontend code - START
    var radioCard = $('.radio-card')
    var itemRadioCard = radioCard.find("input[type='radio']")

    radioCard.click(function () {
      itemRadioCard.removeClass('active')
      $(this).find("input[type='radio']").addClass('active')
      proccessInstallmentsOption($(this).find($('#option3')).length > 0)
    })

    var formPaymentRadio = $('.form-payment-radio .styled-radio input[type= radio]')
    var thisFormPaymentRadio

    formPaymentRadio.click(function () {
      formPaymentRadio.removeClass('active')
      $(this).addClass('active')

      thisFormPaymentRadio = $(this).attr('value')

      if (thisFormPaymentRadio === 'Privatperson') {
        $('.inputPerson').show()
        $('.inputCompany').hide()
        $('.option3').parent().show()
        // Show person
      } else {
        $('.inputPerson').hide()
        $('.inputCompany').show()
        $('.option3').parent().hide()
        // Show company
      }
    })

    // frontend code - END

    manipulateInstallmentDropdown()

    concludePaymentInformantion()

    goToConfirmationPage()

    newsletterRegistration()

    countCharacteresTextArea()

    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - START $$$$$$$$$$$$$$$$$$
    const backPage = $('.checkout__title .back-link a')
    backPage.click(function () {
      if ($(this).attr('href') === 'javascript:void(0)') {
        goBack()
      }
    })
    // $$$$$$$$$$$$$$$$$$ GO BACK BUTTON - END $$$$$$$$$$$$$$$$$$

    formsLib.addInputEventListeners()
  }
})

/**
 * character count for the text area fields of the checkout forms
 */
function countCharacteresTextArea () {
  document.getElementById('questionInfo').onkeyup = function () {
    const count = document.getElementById('countCharacterQuestion')
    count.innerHTML = `${(25000 - this.value.length)}/25000`
    if (this.value.length === 25000) {
      $('#countCharacterQuestion').css({ color: 'red' })
    } else {
      $('#countCharacterQuestion').css({ color: 'gray' })
    }
  }
}

/**
 * handles the selection of items from the "installments" payment option dropdown
 */
function manipulateInstallmentDropdown () {
  var dropDownCard2 = $('.dropDownCard2')
  var selectedItem2 = dropDownCard2.find('.selectedItem')
  var dropDowntoggle2 = dropDownCard2.find('.selectedItem, .arrowSelect')
  var option2 = dropDownCard2.find('.option')
  dropDowntoggle2.click(function () {
    dropDownCard2.toggleClass('active')
  })
  option2.click(function () {
    dropDownCard2.toggleClass('active')
    selectedItem2.html($(this).text())
    selectedItem2.attr('data-value', $(this).text())
    option2.removeClass('active')
    $(this).addClass('active')
  })
}

/**
 * activates a button spinner
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

/**
 * make a request to the backend to subscribe the customer to the site's newsletter
 */
function newsletterRegistration () {
  $('.newsletterOtherPayer').on('click', null, function () {
    $(this).toggleClass('active')
  })

  const newsletterServiceUrl = page.data('newsletter-service')
  const cartIdFromReq = page.data('cartid-from-req')
  // btn-step4

  const buttonNewsletter = $('#btn-step4')

  buttonNewsletter.on('click', null, function (e) {
    if (!termsWereChecked()) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    const inputNewsletter = $('.newsletterOtherPayer.active')
    const footer = $('footer')
    const newsletterConfirmationPage = footer.data('newsletter-confirmation-page')
    if (inputNewsletter.length) {
      $.ajax({
        url: newsletterServiceUrl,
        data: { cartId: cartIdFromReq },
        success: function (data) {
          if (data && (data.status === 'success')) {
            // TODO:
            buttonNewsletter.hide()
            if (newsletterConfirmationPage) {
              window.location.href = newsletterConfirmationPage
            }
          } else {
            // console.log(data)
            // TODO:
          }
        } // end success
      })
    }
  })
}

/**
 * check if the shopping cart exists in the database
 */
function checkCartExit () {
  const checkCartExistServiceUrl = page.data('check-cart-exist')
  const shoppingCartId = page.data('cartid-from-req')
  $.ajax({
    url: checkCartExistServiceUrl,
    type: 'POST',
    async: false,
    data: jQuery.param({ shoppingCartId: shoppingCartId }),
    success: function (data) {
      if (data && !(data.cartExist)) {
        // console.log(data)
        window.scrollTo(0, 0)
        window.location.reload()
      }
    } // end success
  })
}

/**
 * triggers, on click, the functions for processing the chosen payment method
 */
function concludePaymentInformantion () {
  const buttonToConcludePaymentInformantion = $('#btn-conclude-payment-information')

  buttonToConcludePaymentInformantion.on('click', null, function (e) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    const paymentInformation = $('.form-payment-radio .styled-radio input[type= radio].active').attr('value')

    const optionRadioChecked = $('.checkout__section .radio-card input.active')

    const optionRadioId = optionRadioChecked.attr('id')

    if (paymentInformation !== 'Privatperson' && optionRadioId === 'option3') {
      goToScrollTop($('#option1'))
      return
    }

    const paymentMessageBox = $('.payment-method-message')

    if (optionRadioId) {
      handleSelectedPaymentMethod(optionRadioId)
      paymentMessageBox.hide()
    } else {
      goToScrollTop($('.checkout__section .radio-card input'), 135)
      paymentMessageBox.show()
    }
  })
}

/**
 * handles the selected payment method
 * @param {String} selectedMethod selected payment method
 */
function handleSelectedPaymentMethod (selectedMethod) {
  switch (selectedMethod) {
    case 'option1':
      $('#paymentMethodOnConfirmation').text('Kortbetaling')
      createPersonalInformation(selectedMethod)
      break
    case 'option2':
      $('#paymentMethodOnConfirmation').text('Faktura')
      createPersonalInformation(selectedMethod)
      break
    case 'option3':
      $('#paymentMethodOnConfirmation').text('Avbetaling')
      createPersonalInformation(selectedMethod)
  }
}

/**
 * persists the payer's information in the shopping cart and processes the selected payment method
 * @param {String} selectedMethod selected payment method
 * @returns
 */
function createPersonalInformation (selectedMethod) {
  const button = $('#btn-conclude-payment-information')
  setLoadButton(button)
  checkCartExit()
  var serviceUrl = page.data('service')

  const form = document.getElementById('formPaymentInformation')
  const formData = new FormData(form) //eslint-disable-line
  const informationAboutPaying = $('.form-payment-radio .styled-radio input.active').attr('value')
  // const paymentMethod = selectedMethod === 'option1' ? 'credit-card' : ''
  let paymentMethod
  if (selectedMethod === 'option1') {
    paymentMethod = 'credit-card'
  } else if (selectedMethod === 'option2') {
    paymentMethod = 'invoice'
  } else {
    paymentMethod = 'installments'
  }

  const numberOfDownPaymentMonths = $('#option3').hasClass('active') ? parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text()) : 0

  formData.append('informationAboutPaying', informationAboutPaying)
  formData.append('paymentMethod', paymentMethod)
  formData.append('numberOfDownPaymentMonths', numberOfDownPaymentMonths)

  var type = 'checkout-courses-form'
  const shoppingCartId = page.data('cartid-from-req')
  formData.append('type', type)

  formData.append('shoppingCartId', shoppingCartId)
  formData.delete('email')

  const questionInfo = $('#questionInfo').val()

  if (questionInfo.length) {
    formData.append('questionInfo', questionInfo)
  }

  const validationResult = formsLib.handleValidation()

  if (validationResult.hasInvalidFields) {
    removeLoadButton(button)
    const input = validationResult.hasInvalidFields[0] && validationResult.hasInvalidFields[0].input
    goToScrollTop($(input || '.checkout__section form'), 200)
    return
  } else {
    formsLib.markInputsAsProcessed()
  }

  let customerIdOtherPayer

  $.ajax({
    url: serviceUrl,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: function (data) {
      // console.log(data)
      if (data && data.status && data.status === 'success') {
        // console.log(data)
        // customerIdOtherPayer = createOrUpdateContact(shoppingCartId, 'POST')

        const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
        $.ajax({
          url: studentRegisterSalesforceService,
          // async: false,
          data: { shoppingCartId: shoppingCartId, method: 'POST', action: 'contact', otherPayerForm: true },
          success: function (data) {
            if (data) {
              // console.log(data)
              customerIdOtherPayer = data && data.customerId

              if (!customerIdOtherPayer) {
                displaySalesforcePageError($('#btn-conclude-payment-information'))
                removeLoadButton(button)
                return
              }
              if (selectedMethod === 'option1') {
                netaxeptRedirect()
              } else if (selectedMethod === 'option2') {
                salesforceInstallmentsInvoice.processPayment($, page.data('cartid-from-req'), true, true)
              } else if (selectedMethod === 'option3') {
                salesforceInstallmentsInvoice.processPayment($, page.data('cartid-from-req'), true, true)
              }
            }
          }
        })
      } else {
        // console.log(data)
        if (data && !data.cartExist) {
          window.scrollTo(0, 0)
          window.location.reload()
        }
      }
    }
  })
}

/**
 * sends the data for the payment to be completed using the credit card payment method to the NETS API.
 * It then redirects the user to the NETS payment portal
 */
function netaxeptRedirect () {
  const button = $('#btn-conclude-payment-information')
  const serviceUrl = page.data('netaxept-service')
  // const amount = page.data('amount')
  const shoppingCartId = page.data('cartid-from-req')
  const crmOrganisation = page.data('crm-organisation')
  // console.log(serviceUrl)

  setLoadButton(button)
  $.ajax({
    url: serviceUrl,
    type: 'POST',
    data: jQuery.param({ shoppingCartId: shoppingCartId, crmOrganisation: crmOrganisation }),
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    success: function (data) {
      if (data && data.success) {
        // createOrUpdateOpportunitySalesforce(shoppingCartId, 'POST')
        // console.log(data)
        window.location.href = data.paymentRedirect
      } else {
        appendErrorMessage($('.step1 .messageError'))
        goToScrollTop($('#addedMessageError'))
        removeLoadButton(button)
      }
    }
  })
}

/**
 * adds an error message to the page if the payment method fails.
 * @param {*} htmlSectionToAppend section where the error message should be added
 */
function appendErrorMessage (htmlSectionToAppend) {
  const hasMessageErrorOnPage = $('#addedMessageError')
  if (!hasMessageErrorOnPage.length) {
    const responseCode = page.data('response-code')
    const message = responseCode === 'Cancel' ? `${strings.netsCancel}` : `${strings.netsError}`
    // const htmlSectionToAppend = $('.step3 .messageError')
    htmlSectionToAppend.prepend(
      `<div class='checkout__section' id='addedMessageError'>
        <div class="row resize-col">
          <div class="col-lg-10">
            <div class="box-orange">
              <p>${message}</p>
            </div>
          </div>
        </div>
      </div>`
    )
  }
}

/**
 * if the payment operation is successful then the user is redirected to the payment screen.
 */
function goToConfirmationPage () {
  const cartIdFromReq = page.data('cartid-from-req')
  const authorizePaymentResponse = page.data('authorize-payment-response')
  if (cartIdFromReq && authorizePaymentResponse) {
    $('.step1').hide()
    $('.step2').hide()
    $('.step3').hide()
    $('.step4').show()

    $('.header-step1').removeClass('active done')
    $('.header-step1').addClass('disabled')
    $('.header-step2').removeClass('active done')
    $('.header-step2').addClass('disabled')
    $('.header-stepOptionalDocumentation').removeClass('active done')
    $('.header-stepOptionalDocumentation').addClass('disabled')
    $('.header-step3').removeClass('active done')
    $('.header-step3').addClass('disabled')
    $('.header-step4').removeClass('disabled')
    $('.header-step4').addClass('active')

    const customerInfo = page.data('get-cart-customer-info')

    if (customerInfo) {
      $.ajax({
        url: customerInfo,
        type: 'POST',
        data: jQuery.param({ cartIdFromReq: cartIdFromReq }),
        success: function (data) {
          if (data) {
            // console.log(data)
            if (data.cartStatus === 'checkout_complete') {
              // gaDataLayer.processGADataLayerPurchase($, cartIdFromReq, 'creditcard', false)
            }
            // append to section: Takk for din bestilling
            $('.checkout__text-info.withTitle.col-lg-8').append(`<ul>
            <li>
              I løpet av et par dager vil du motta bekreftelse på din
              påmelding samt bruker og passord til din e-post: <a
                 ${data.inputEmail ? `href=mailto: ${data.inputEmail}` : ''}
                 class="link-b-orange">${data.inputEmail ? `${data.inputEmail}` : ''}</a>
            </li>
          </ul>
          <h6>Er det noe du lurer på? Kontakt oss gjerne:</h6>

          <p>man-fre: 09-16</p>
          <p>67 58 88 00</p>`)

            // append to section: Student
            $('.checkout__section.student.customerInformation').append(`<div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputName">Navn</label>
              ${data.inputName && data.inputLastName ? `<div>${data.inputName} ${data.inputLastName}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputEmail">E-post </label>
            ${data.inputEmail ? `<div>${data.inputEmail}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputPhoneNumber">Telefonnummer</label>
              ${data.inputPhoneNumber ? `<div>${data.inputCountryCode || ''}${data.inputPhoneNumber}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputAddress">Gate/postboks</label>
              ${data.inputAddress ? `<div>${data.inputAddress}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputNumber">Sted</label>
              ${data.inputZipCode ? `<div>${data.inputZipCode}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputLand">Land</label>
              ${data.inputCountry && data.inputCountry.name ? `<div>${capitalize(data.inputCountry.name, true)}</div>` : ''}
            </div>
          </div>`)

            // const result = createOrUpdateOpportunitySalesforce(page.data('cartid-from-req'), true, true)
            // const orderId = result && result.orderId
            // if (!orderId) {
            //   // displaySalesforcePageError(saveDocuments($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError), $('#btn-setDocumentation'))
            //   // removeLoadButton(button)
            //   // displaySalesforcePageError($('#btn-setDocumentation'))
            //   // return
            // }

            const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
            // const customerPaymentOption = {
            //   paymentOption: 'credit-card', paymentOptionVariant: undefined, numberOfDownPaymentMonths: 0
            // }
            $.ajax({
              url: studentRegisterSalesforceService,

              data: { shoppingCartId: page.data('cartid-from-req'), action: 'opportunity', paymentStep: true, otherPayerForm: true },
              success: function (data) {
                if (data) {
                  // console.log(data)
                }
              }
            })
          }
        } // end success
      })
    }
  } else if (cartIdFromReq && authorizePaymentResponse === false) {
    appendErrorMessage($('.step1 .messageError'))
    goToScrollTop($('#addedMessageError'))
  }
}

/**
 * runs a request to the salesforce API to create or update an opportunity.
 * @param {String} shoppingCartId  the current shopping cart id
 * @param {Boolean} paymentStep indicates if the checkout is in the payment step
 * @param {Boolean} async indicates whether the request should be asynchronous or not
 * @returns {Object} result of request to salesforce API containing "orderId"
 */
function createOrUpdateOpportunitySalesforce (shoppingCartId, paymentStep, async = false) {
  let result
  // const page = $('#checkoutCoursePageIDSalesforce')
  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
  $.ajax({
    url: studentRegisterSalesforceService,
    async: async,
    data: { shoppingCartId: shoppingCartId, action: 'opportunity', paymentStep: paymentStep, otherPayerForm: true },
    success: function (data) {
      if (data) {
        // console.log(data)
        result = data
      }
    }
  })
  return result
}

/**
 * displays salesforce checkout error screen
 * @param {*} elementToScroll element the page should scroll to if the user clicks retry.
 */
function displaySalesforcePageError (elementToScroll) {
  const cartIdFromReq = page.data('cartid-from-req')

  $('#checkoutError .fail__section .btn__try-again').on('click', null, function (event) {
    // callback()
    event.preventDefault()
    $('#checkoutError').hide()
    $('#checkoutOtherPayerSalesforce').show()
    $('.header__wrapper .main__menu').show()
    goToScrollTop(elementToScroll)
  })

  if (cartIdFromReq) {
    // if (cartIdFromReq !== cartIdFromCookie) {
    // console.log(cartIdFromReq)
    const checkCartExistServiceUrl = page.data('check-cart-exist')
    $.ajax({
      url: checkCartExistServiceUrl,
      type: 'POST',
      async: false,
      data: jQuery.param({ shoppingCartId: cartIdFromReq }),
      success: function (data) {
        if (data && (data.cartExist)) {
          // console.log(data.cart)
          const cart = data.cart

          $('#checkoutError .contact__section .user-info .user-info-inputName').text(`${cart.data.customerInfo.inputName} ${cart.data.customerInfo.inputLastName}`)
          $('#checkoutError .contact__section .user-info .user-info-inputEmail').text(cart.data.customerInfo.inputEmail)
          $('#checkoutError .contact__section .user-info .user-info-inputPhoneNumber').text(cart.data.customerInfo.inputCountryCode || '' + cart.data.customerInfo.inputPhoneNumber)
          $('#checkoutError .contact__section .user-info .user-info-inputAddress').text(cart.data.customerInfo.inputAddress)
          $('#checkoutError .contact__section .user-info .user-info-inputCity').text(cart.data.customerInfo.inputCity)
          $('#checkoutError .contact__section .user-info .user-info-inputCountry').text(cart.data.customerInfo.inputCountry.name)

          $('#checkoutError').show()
          $('#checkoutOtherPayerSalesforce').hide()
          $('.header__wrapper .main__menu').hide()
          goToScrollTop($('#checkoutError'))
        }
      } // end success
    })
    // }
  }
}

/**
 * apply capitalization to a string
 * @param {String} str string that must be modified
 * @param {Boolean} lower apply lower case to string
 * @returns {String} modified string
 */
function capitalize (str, lower = false) {
  // console.log(str)
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

/**
 * check if all form fields are filled in correctly
 * @param {*} formData data filled in the form
 * @returns {Boolean} true if there is an error
 */
function checkRequiredFields (formData) { //eslint-disable-line
  const informationAboutPaying = formData.get('informationAboutPaying')
  const inputCompany = formData.get('inputCompany')
  const inputCompanyNumber = formData.get('inputCompanyNumber')
  const inputName = formData.get('inputName')
  const inputLastName = formData.get('inputLastName')
  const inputSocialNumber = formData.get('inputSocialNumber')
  const inputEmail = formData.get('inputEmail')
  const inputPhoneNumber = formData.get('inputPhoneNumber')
  const inputZipCode = formData.get('inputZipCode')
  const inputCity = formData.get('inputCity')
  const inputCountry = formData.get('inputCountry')
  const inputCountryCode = formData.get('inputCountryCode')
  const inputAddress = formData.get('inputAddress')
  const invalidFieldsToBeScrolledTo = []

  let backToTop = false

  if (informationAboutPaying !== 'Privatperson') {
    if (inputCompany === '') {
      const inputCompanyElement = $('#inputCompany')
      inputCompanyElement.attr('placeholder', 'Påkrevd')
      inputCompanyElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputCompanyElement)
      backToTop = true
    } else {
      const inputCompanyElement = $('#inputCompany')
      inputCompanyElement.removeClass('invalid')
    }

    if (inputCompanyNumber === '') {
      const inputCompanyNumberElement = $('#inputCompanyNumber')
      inputCompanyNumberElement.attr('placeholder', 'Påkrevd')
      inputCompanyNumberElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputCompanyNumberElement)
      backToTop = true
    } else {
      const inputCompanyNumberElement = $('#inputCompanyNumber')

      const validator = inputCompanyNumber.length
      if (validator !== 9) {
        inputCompanyNumberElement.addClass('invalidField')
        invalidFieldsToBeScrolledTo.push(inputCompanyNumberElement)
        backToTop = true
      } else {
        inputCompanyNumberElement.removeClass('invalid')
        inputCompanyNumberElement.removeClass('invalidField')
      }

      inputCompanyNumberElement.removeClass('invalid')
    }
  }

  if (informationAboutPaying === 'Privatperson') {
    if (inputName === '') {
      const inputNameElement = $('#inputName')
      inputNameElement.attr('placeholder', 'Påkrevd')
      inputNameElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputNameElement)
      backToTop = true
    } else {
      const inputNameElement = $('#inputName')
      inputNameElement.removeClass('invalid')
    }

    if (inputLastName === '') {
      const inputLastNameElement = $('#inputLastName')
      inputLastNameElement.attr('placeholder', 'Påkrevd')
      inputLastNameElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputLastNameElement)
      backToTop = true
    } else {
      const inputLastNameElement = $('#inputLastName')
      inputLastNameElement.removeClass('invalid')
    }

    if (inputPhoneNumber === '') {
      const inputPhoneNumberElement = $('#inputPhoneNumber')
      inputPhoneNumberElement.attr('placeholder', 'Påkrevd')
      inputPhoneNumberElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputPhoneNumberElement)
      backToTop = true
    } else {
      const inputPhoneNumberElement = $('#inputPhoneNumber')
      inputPhoneNumberElement.removeClass('invalid')
    }

    if (inputEmail === '') {
      const inputEmailElement = $('.form-group.form-required input.inputEmail')
      inputEmailElement.attr('placeholder', 'Påkrevd')
      inputEmailElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputEmailElement)
      backToTop = true
    } else {
      const validEmail = validateEmail(inputEmail)
      if (!validEmail) {
        const inputEmailElement = $('.form-group.form-required input.inputEmail')
        if (inputEmailElement.hasClass('invalid')) {
          inputEmailElement.removeClass('invalid')
        }
        inputEmailElement.attr('placeholder', 'Påkrevd')
        inputEmailElement.addClass('invalidField')
        invalidFieldsToBeScrolledTo.push(inputEmailElement)
        backToTop = true
      } else {
        const inputEmailElement = $('.form-group.form-required input.inputEmail')
        if (inputEmailElement.hasClass('invalidField')) {
          inputEmailElement.removeClass('invalidField')
          // inputEmailElement.addClass('invalid')
          // console.log('has invalidField')
        }
      }
    }

    if (inputSocialNumber === '') {
      const inputSocialNumberElement = $('#inputSocialNumber')
      inputSocialNumberElement.attr('placeholder', 'Påkrevd')
      inputSocialNumberElement.addClass('invalid')
      invalidFieldsToBeScrolledTo.push(inputSocialNumberElement)
      backToTop = true
    } else {
      const inputSocialNumberElement = $('#inputSocialNumber')

      // if (informationAboutPaying === 'Privatperson') {
      const validator = fnrvalidator.fnr(inputSocialNumber)

      if (inputSocialNumber === '6073910828') {
        validator.status = 'valid'
      }

      if ((validator && validator.status && validator.status === 'invalid')) {
        inputSocialNumberElement.addClass('invalidField')
        invalidFieldsToBeScrolledTo.push(inputSocialNumberElement)
        backToTop = true
      } else {
        inputSocialNumberElement.removeClass('invalid')
        inputSocialNumberElement.removeClass('invalidField')
      }
      // }
    }
  }

  if (inputAddress === '') {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.attr('placeholder', 'Påkrevd')
    inputAddressElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputAddressElement)
    backToTop = true
  } else {
    const inputAddressElement = $('#inputAddress')
    inputAddressElement.removeClass('invalid')
  }

  if (inputZipCode === '') {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.attr('placeholder', 'Påkrevd')
    inputZipCodeElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputZipCodeElement)
    backToTop = true
  } else {
    const inputZipCodeElement = $('#inputZipCode')
    inputZipCodeElement.removeClass('invalid')
  }

  if (inputCity === '') {
    const inputCityElement = $('#inputCity')
    inputCityElement.attr('placeholder', 'Påkrevd')
    inputCityElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputCityElement)
    backToTop = true
  } else {
    const inputCityElement = $('#inputCity')
    inputCityElement.removeClass('invalid')
  }

  if (inputCountryCode === '') {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.attr('placeholder', 'Påkrevd')
    inputCountryCodeElement.addClass('invalid')
    invalidFieldsToBeScrolledTo.push(inputCountryCodeElement)
    backToTop = true
  } else {
    const inputCountryCodeElement = $('#inputCountryCode')
    inputCountryCodeElement.removeClass('invalid')
  }

  if (!inputCountry) {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.addClass('invalidFieldSelect')
    invalidFieldsToBeScrolledTo.push(inputCountryElement)
    backToTop = true
  } else {
    const inputCountryElement = $('#inputCountry')
    inputCountryElement.removeClass('invalidFieldSelect')
  }

  if (backToTop) {
    if (invalidFieldsToBeScrolledTo.length) {
      goToScrollTop(invalidFieldsToBeScrolledTo[0], 200)
    } else {
      goToScrollTop($('.checkout__section form'), 150)
    }
  }

  return backToTop
}

/**
 * scrolls page to selected element
 * @param {*} element
 */
function goToScrollTop (element, h = 20) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top - h
    }, 700)
}

/**
 * check if the format of an email is valid
 * @param {String} email
 * @returns {Boolean}
 */
function validateEmail (email) {
  const re = /^[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:[._-][a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*@[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:-[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,}(?:\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

/**
 * runs a request to the salesforce API to create or update contact.
 * @param {String} shoppingCartId  the current shopping cart id
 * @param {String} method POST or PUT
 * @returns {Object} result of request to salesforce API containing "customerId"
 */
function createOrUpdateContact (shoppingCartId, method) {
  let result
  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
  $.ajax({
    url: studentRegisterSalesforceService,
    async: false,
    data: { shoppingCartId: shoppingCartId, method: method, action: 'contact', otherPayerForm: true },
    success: function (data) {
      if (data) {
        // console.log(data)
        result = data
      }
    }
  })
  return result && result.customerId
}

/**
 * verifies that the selected payment option was "installment", if it is, recalculates the shopping cart price based on the number of selected installments and displays it on the screen.
 * It recalculates the price returning to the original if the selected payment option is not "installments"
 * @param {Boolean} isInstallmentOption indicates if the selected payment option is "Installments"
 */
function proccessInstallmentsOption (isInstallmentOption) {
  if (isInstallmentOption) {
    const shoppingCartId = page.data('cartid-from-req')
    const serviceUrl = page.data('checkout-courses-general-service')

    $.ajax({
      url: serviceUrl,
      // async: false,
      data: { shoppingCartId: shoppingCartId, action: 'retrievePrice' },
      success: function (data) {
        if (data) {
          const dataPrice = data && data.totalPrice
          const oldTotalPrice = dataPrice && dataPrice.oldTotalPrice
          const price = dataPrice && dataPrice.priceWithDiscount
          const interestRate = page.data('interestrate')
          const monthsOption = parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text())

          const sumInterest = (price / 100) * ((interestRate / 12) * monthsOption)
          // console.log(`(${price} / 100) * ((${interestRate} / 12) * ${monthsOption})`)
          // console.log(sumInterest)
          const monthlyFee = page.data('monthlyfee')
          const sumMonthlyFee = monthlyFee * monthsOption

          const setupFee = page.data('setupfee')

          const interestAndFees = parseFloat(setupFee) + parseFloat(sumInterest) + parseFloat(sumMonthlyFee)
          const effectiveInterestRate = ((interestAndFees * 100) / price).toFixed(2)

          const htmlCouponDiscount = $('.step1 .couponDiscountBasket').html()
          $('.step1 li.couponDiscountBasket, .step4 li.couponDiscountBasket').remove()

          $('li#interestAndFees').remove()
          const basketItems = $('.step1 .checkout__bottom.basket__total .basket__total__items, .checkout__section .checkout__close__basket .items')

          if (htmlCouponDiscount) {
            basketItems.append(
              `
              <li class="couponDiscountBasket">
                ${htmlCouponDiscount}
              </li>
              `
            )
          }

          basketItems.append(
            `
            <li id="interestAndFees">
              <div>Renter og Gebyrer (Effektiv rente ${effectiveInterestRate}%)</div>
              <div class="value" >${formatMoney(interestAndFees)}</div>
            </li>
            `
          )

          $('.step1 .basket__total__value .value').text(formatMoney(price + interestAndFees))
          $('.step4 .checkout__close__basket__total .value').text(formatMoney(price + interestAndFees))
        }
      } // end success
    })
  } else {
    $('li#interestAndFees').remove()
    const dataPrice = coupon.retrieveTotalPrice($)
    if (dataPrice && dataPrice.totalPrice && dataPrice.totalPrice.totalPriceWithMask) {
      $('.step1 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.step4 .checkout__close__basket__total .value').text(dataPrice.totalPrice.totalPriceWithMask)
    }
  }
}

/**
 * formats the shopping cart value to the currency format defined in the project
 * @param {Number} amount value to be converted
 * @param {Number} decimalCount number of decimal unit
 * @param {String} decimal separator used for decimal unit
 * @param {String} thousands separator used for the thousand unit
 * @returns {String} the amount converted to the currency format defined in the project
 */
function formatMoney (amount, decimalCount = 2, decimal = ',', thousands = ' ') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0

    const newAmount = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    const newAmountWithMask = (`kr ${newAmount}`).replace(',00', ',-')
    return newAmountWithMask
  } catch (e) {
    // console.log(e)
  }
}

/**
 * simulates the browser's back button
 */
function goBack () {
  if (window.history.length >= 2) {
    // if history is not empty, go back:
    window.history.back()
  }
  // else {
  //   // go home:
  //   window.history.replaceState(null, null, '/')
  //   window.location.replace('/')
  // }
}

function termsWereChecked () {
  const checkbox = $('.checkout__bottom__content input[type=checkbox]:visible')
  if (!checkbox.is(':checked')) {
    checkbox.parent().addClass('invalid')
    return false
  }
  checkbox.parent().removeClass('invalid')
  return true
}
