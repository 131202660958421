const arrayOfFiles = []

/**
 * checks whether the documentation step is needed at checkout, if not, the step is not displayed in the header
 * @param {*} $ jQuery
 */
function checkAdmissionRequeriment ($) {
  const page = $('#checkoutCoursePageIDSalesforce')

  const admissionRequeriments = page.data('admission-requeriments')
  if (!admissionRequeriments) {
    $('#header-stepOptionalDocumentation').remove()
    $('.stepOptionalDocumentation').remove()
  }
  // console.log(admissionRequeriments)
}

/**
 * handles the display of the checkout steps in the header
 * @param {*} $ jQuery
 */
function setHeaderOptions ($) {
  const page = $('#checkoutCoursePageIDSalesforce')

  const admissionRequeriments = page.data('admission-requeriments')
  if (!admissionRequeriments) {
    $('.step2').hide()
    $('.step3').show()

    $('.header-step2').removeClass('active')
    $('.header-step2').addClass('done')

    $('.header-step3').removeClass('disabled')
    if ($('.header-step3').hasClass('done')) $('.header-step3').removeClass('done')
    $('.header-step3').addClass('active')
  } else {
    $('.step2').hide()
    $('.stepOptionalDocumentation').show()

    $('.header-step2').removeClass('active')
    $('.header-step2').addClass('done')

    $('.header-stepOptionalDocumentation').removeClass('disabled')
    if ($('.header-stepOptionalDocumentation').hasClass('done')) $('.header-stepOptionalDocumentation').removeClass('done')
    $('.header-stepOptionalDocumentation').addClass('active')
  }
}

/**
 * allows the user to select documents on their computer and add them to the form.
 * @param {*} $ jQuery
 */
function setDocumentationForm ($) {
  const buttonFileUpload = $('#fileToUpload')
  buttonFileUpload.click(function (event) {
    onInputClick(event)
  })

  if (buttonFileUpload.length) {
    document.getElementById('fileToUpload').onchange = function () { appendFileHtml($) }
  }

  const buttonRemoveFile = $('.btn-file--list')
  if (buttonRemoveFile.length) {
    buttonRemoveFile.on('click', '.btn-secondary', removeFile($))
  }
}

const onInputClick = (event) => {
  event.target.value = ''
}

/**
 * populates the array of files when loading the page according to previously uploaded files.
 * @param {*} $ jQuery
 */
function populateArrayOfFiles ($) {
  const page = $('#checkoutCoursePageIDSalesforce')

  const filesDocumentation = page.data('files-documentation')
  // console.log(filesDocumentation)
  filesDocumentation && filesDocumentation.forEach(file => {
    arrayOfFiles.push({ name: file.name, id: file.id })
  })
  // console.log(arrayOfFiles)
}

/**
 * displays files added to the form by the user on the page, allowing them to be uploaded or removed
 * @param {*} $ jQuery
 */
function appendFileHtml ($) {
  const elementWithFiles = document.getElementById('fileToUpload')
  const arrayOfFilesFromForm = elementWithFiles && elementWithFiles.files

  // console.log(arrayOfFilesFromForm.length)
  if (arrayOfFilesFromForm && arrayOfFilesFromForm.length) {
    for (let h = 0; h < arrayOfFilesFromForm.length; h++) {
      // console.log(arrayOfFilesFromForm[h])
      let hasFile = false
      if (arrayOfFilesFromForm[h] && arrayOfFilesFromForm[h].name) {
        for (let i = 0; i < arrayOfFiles.length; i++) {
          // formData.append('arrayOfFiles', arrayOfFiles[i])
          if (arrayOfFilesFromForm[h].name === arrayOfFiles[i].name) {
            // console.log('this file has already been selected.')
            hasFile = true
          }
        }

        if (!hasFile) {
          arrayOfFiles.push(arrayOfFilesFromForm[h])
          document.querySelectorAll('#documentationDelivery option').forEach((option, index) => {
            if (index === 1) {
              option.setAttribute('selected', 'selected')
              $('#formDocumentation .btn.dropdown-toggle.btn-light .filter-option-inner-inner').text(option.innerHTML)
            } else {
              option.removeAttribute('selected')
            }
          })

          $('.checkout__section.formGroup .btn-file--list').append(`<li>
        <div>${arrayOfFilesFromForm[h] && arrayOfFilesFromForm[h].name} <button
                    class="btn btn-secondary" type="button" value='${arrayOfFilesFromForm[h] && arrayOfFilesFromForm[h].name}'>Slett fil</button></div>
        </li>`)
        }
      }
    }
  }
}

/**
 * removes the document from displaying on the page and also from the shopping cart.
 * @param {*} $ jQuery
 */
function removeFile ($) {
  return function (e) {
    const _this = $(this)
    // $(this).parent().parent().remove()
    // console.log(arrayOfFiles)
    // console.log($(this).attr('value'))
    // console.log($(this).parent().attr('id'))
    const fileToRemoveId = $(this).parent().attr('id')

    if (!fileToRemoveId) {
      _this.parent().parent().remove()
      const valueToSearchIndex = $(this).attr('value')

      for (let i = 0; i < arrayOfFiles.length; i++) {
        // formData.append('arrayOfFiles', arrayOfFiles[i])
        if (arrayOfFiles[i].name === valueToSearchIndex) {
          // console.log(i)
          if (i > -1) {
            arrayOfFiles.splice(i, 1)
          }
        }
      }
      // console.log(arrayOfFiles)
    } else {
      const page = $('#checkoutCoursePageIDSalesforce')
      const serviceUrl = page.data('checkout-courses-general-service')
      // console.log(fileToRemoveId)
      $.ajax({
        url: serviceUrl,
        data: { action: 'removeFileFromCart', fileToRemoveId: fileToRemoveId },
        success: function (data) {
          if (data && data.fileWasRemoved) {
            // console.log(data.fileWasRemoved)
            _this.parent().parent().remove()
            for (let i = 0; i < arrayOfFiles.length; i++) {
              if (arrayOfFiles[i].id === fileToRemoveId) {
                if (i > -1) {
                  arrayOfFiles.splice(i, 1)
                }
              }
            }
          } else {
            let errorMessageElement = $('.documentError')
            if (!errorMessageElement.length) {
              // console.log('box')
              appendErrorMessage($)
              errorMessageElement = $('.documentError')
            }

            if (errorMessageElement.length) {
              goToScrollTop($, $('.page'))
            }
          }
        }
      })
    }
  }
}

/**
 * on click triggers the request to persist the documents in the shopping cart
 * @param {*} $ jQuery
 * @param {*} jQuery jQuery
 * @param {Function} checkCartExist callback function to check if the shopping cart exists
 * @param {Function} updateCookie callback function update the cookie saved in the browser
 * @param {Function} displaySalesforcePageError callback function to display checkout-salesforce error screen
 */
function sendForm ($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError) {
  if ($('#btn-setDocumentation').length) {
    document.getElementById('btn-setDocumentation').addEventListener('click', saveDocuments($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError), false)
  }
}

/**
 * defines how the customer's documentation is delivered
 * @param {*} $ jQuery
 * @param {Object} strings object with the strings used in the checkout
 * @returns {String} how the customer's documentation is delivered
 */
function setDocumentationDelivery ($, strings) {
  const uploaded = strings && strings.uploadedDocumentation
  const post = strings && strings.postDocumentation
  const email = strings && strings.emailDocumentation
  const defaultOption = strings && strings.defaultDocumentationInformation

  const howToSendDocumentation = $('#formDocumentation .btn.dropdown-toggle.btn-light .filter-option-inner-inner').text()
  if (howToSendDocumentation) {
    switch (howToSendDocumentation) {
      case uploaded:
        return 'uploaded'

      case defaultOption:
      case post:
        return 'post'

      case email:
        return 'email'
    }
  }
}

function verifyDocumentation ($) {
  const page = $('#checkoutCoursePageIDSalesforce')
  const documentationDelivery = setDocumentationDelivery($, page.data('strings'))
  const hasFiles = arrayOfFiles.length
  if (!documentationDelivery || (documentationDelivery === 'uploaded' && !hasFiles)) {
    $('#formDocumentation .dropdown.bootstrap-select.form-control.select').addClass('invalid')
    return false
  } else {
    return true
  }
}

let skipCreateContactAndOpportunitySaveDocuments = 1 // prevents opportunity from being created/updated if checkout is coming back from NETS API error
/**
 * persists the documents selected by the user in the shopping cart
 * @param {*} $ jQuery
 * @param {*} jQuery jQuery
 * @param {Function} checkCartExist callback function to check if the shopping cart exists
 * @param {Function} updateCookie callback function update the cookie saved in the browser
 * @param {Function} displaySalesforcePageError callback function to display checkout-salesforce error screen
 */
function saveDocuments ($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError) {
  return function (e) {
    // $('#checkoutError').hide()
    // $('#checkoutCoursePageIDSalesforce').show()
    // $('.header__wrapper .main__menu').show()
    const button = $('#btn-setDocumentation')
    setLoadButton(button)
    // $('#btn-setDocumentation').hide()
    const cart = checkCartExist()

    if (!cart || !cart.data || (!cart.data.items && !cart.data.courseItems)) {
      displaySalesforcePageError($('#btn-setDocumentation'))
      return
    }

    updateCookie()
    const page = $('#checkoutCoursePageIDSalesforce')
    const serviceUrl = page.data('checkout-courses-general-service')
    const formData = new FormData($('#formDocumentation')[0]) //eslint-disable-line
    formData.append('shoppingCartId', page.data('cartid-from-req'))
    formData.append('action', 'saveDocuments')
    // formData.delete('fileToUpload')
    const transactionId = page.data('transaction-id')
    const responseCode = page.data('response-code')
    // const authorizePaymentResponse = page.data('authorize-payment-response')
    const skipCreateContactAndOpportunity = !!(transactionId && responseCode)
    if (skipCreateContactAndOpportunity) {
      skipCreateContactAndOpportunitySaveDocuments = skipCreateContactAndOpportunitySaveDocuments - 1
    }

    const documentationDelivery = setDocumentationDelivery($, page.data('strings'))
    const hasFiles = arrayOfFiles.length
    if (!documentationDelivery || (documentationDelivery === 'uploaded' && !hasFiles)) {
      removeLoadButton(button)
      $('#formDocumentation .dropdown.bootstrap-select.form-control.select').addClass('invalid')
      goToScrollTop($, $('#documentationDelivery'), 250)
      return
    } else {
      $('#formDocumentation .dropdown.bootstrap-select.form-control.select').removeClass('invalid')
      formData.append('documentationDelivery', documentationDelivery)
    }
    // console.log(documentationDelivery)

    let hasInvalidFiles = false
    let firstInvalidElement = false
    for (let i = 0; i < arrayOfFiles.length; i++) {
      const extensionFile = arrayOfFiles[i] && arrayOfFiles[i].name && (arrayOfFiles[i].name.slice(arrayOfFiles[i].name.lastIndexOf('.', arrayOfFiles[i].name.length))).trim().toLowerCase()
      if (extensionFile !== '.doc' && extensionFile !== '.docx' && extensionFile !== '.pdf' && extensionFile !== '.jpg' && extensionFile !== '.jpeg' && extensionFile !== '.gif' && extensionFile !== '.png' && extensionFile !== '.tiff' && extensionFile !== '.bmp') {
      // console.log('File extension not allowed. Remove files that are not allowed.')
        const fileName = arrayOfFiles[i] && arrayOfFiles[i].name

        const fileElement = $('.btn.btn-secondary').filter(function () { return this.value === fileName }).parent()
        fileElement.addClass('invalid')
        if (!firstInvalidElement) {
          goToScrollTop($, fileElement)
          firstInvalidElement = true
        }
        removeLoadButton(button)
        hasInvalidFiles = true
        // return
      }
    }
    if (hasInvalidFiles) {
      return
    }

    for (let i = 0; i < arrayOfFiles.length; i++) {
      // console.log(arrayOfFiles[i])
      if (arrayOfFiles[i] && !arrayOfFiles[i].id) {
        formData.append('arrayOfFiles', arrayOfFiles[i])
        arrayOfFiles[i].id = 'dummyId'
      }
    }

    //   console.log(formData.getAll('arrayOfFiles'))

    formData.set('fileToUpload', 'arrayOfFiles')

    $.ajax({
      url: serviceUrl,
      data: formData,
      type: 'POST',
      // async: false,
      processData: false,
      contentType: false,
      success: function (data) {
        if (data && data.status) {
          // console.log(data.arrayFilesCreated)

          window.setTimeout(function () {
          // do whatever you want to do

            if (skipCreateContactAndOpportunitySaveDocuments != 0) {
              const result = createOrUpdateOpportunitySalesforce($, page.data('cartid-from-req'))
              const orderId = result && result.orderId
              if (!orderId) {
              // displaySalesforcePageError(saveDocuments($, jQuery, checkCartExist, updateCookie, displaySalesforcePageError), $('#btn-setDocumentation'))
                data.arrayFilesCreated.forEach(itemCreated => {
                // console.log(itemCreated.fileCreated)
                  const itemToCompare = itemCreated.fileCreated
                  arrayOfFiles.forEach((itemArray, index) => {
                    if (itemArray.name === itemToCompare.file) {
                      arrayOfFiles[index].id = itemToCompare._id
                      // console.log(arrayOfFiles[index])
                      $('.btn-file--list :input').filter(function () { return this.value === itemToCompare.file }).parent().attr('id', itemToCompare._id)
                    }
                  })
                })
                removeLoadButton(button)
                displaySalesforcePageError($('#btn-setDocumentation'))
                return
              }
            }

            data.arrayFilesCreated.forEach(itemCreated => {
            // console.log(itemCreated.fileCreated)
              const itemToCompare = itemCreated.fileCreated
              arrayOfFiles.forEach((itemArray, index) => {
                if (itemArray.name === itemToCompare.file) {
                  arrayOfFiles[index].id = itemToCompare._id
                  // console.log(arrayOfFiles[index])
                  $('.btn-file--list :input').filter(function () { return this.value === itemToCompare.file }).parent().attr('id', itemToCompare._id)
                }
              })
            })
            goToScrollTop($, $('.page'))

            $('.header-stepOptionalDocumentation').removeClass('active')
            $('.header-stepOptionalDocumentation').addClass('done')

            $('.header-step3').removeClass('disabled')
            $('.header-step3').addClass('active')

            $('.stepOptionalDocumentation').hide()
            $('.step3').show()
            // $('#btn-setDocumentation').show()

            removeLoadButton(button)
          }, 3000)
        } else {
        // console.log(data)
          let errorMessageElement = $('.documentError')
          if (!errorMessageElement.length) {
            // console.log('box')
            appendErrorMessage($)
            errorMessageElement = $('.documentError')
          }

          if (errorMessageElement.length) {
            goToScrollTop($, $('.page'))
          }
          // $('#btn-setDocumentation').show()
          removeLoadButton(button)
        }
        // window.setTimeout(function () {
        //   // do whatever you want to do
        //   createOrUpdateOpportunitySalesforce($, page.data('cartid-from-req'), 'PUT')
        // }, 5000)
      }
    })
  }
}

/**
 * adds an error message to the page if the payment method fails.
 * @param {*} $ jQuery
 */
function appendErrorMessage ($) {
  const htmlSectionToAppend = $('.checkout__title.errorMessageSection')
  // console.log(htmlSectionToAppend)
  htmlSectionToAppend.append(
      `<div class="row resize-col">
    <div class="col-lg-10">
      <div class="box-orange documentError">
        <p>Et problem oppstod under kommunikasjon med serveren</p>
      </div>
    </div>
  </div>`)
}

/**
 * runs a request to the salesforce API to create or update an opportunity.
 * @param {*} $ jQuery
 * @param {String} shoppingCartId  the current shopping cart id
 */
function createOrUpdateOpportunitySalesforce ($, shoppingCartId) {
  let result
  const page = $('#checkoutCoursePageIDSalesforce')
  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
  $.ajax({
    url: studentRegisterSalesforceService,
    async: false,
    data: { shoppingCartId: shoppingCartId, action: 'opportunity' },
    success: function (data) {
      if (data) {
        // console.log(data)
        result = data
      }
    }
  })
  return result
}

/**
 * scrolls page to selected element
 * @param {*} jQuery
 * @param {*} element
 * @param {Number} h height the page should scroll to the element.
 */
function goToScrollTop ($, element, h = 150) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top - h
    }, 700)
}

/**
 * activates a button spinner
  * @param $ jQuery
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param $ jQuery
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

module.exports = {
  checkAdmissionRequeriment,
  setHeaderOptions,
  setDocumentationForm,
  sendForm,
  populateArrayOfFiles,
  saveDocuments,
  verifyDocumentation
}
