import $ from 'jquery'

$(document).ready(function () {
  $('form#newsletter-macro').submit(function (e) {
    e.preventDefault()
  })

  $('form#newsletter-macro .button-wrapper button').on('click', function (e) {
    const macroElement = $(this).parent().parent().parent()
    const service = macroElement.data('newsletter-service')
    const newsletterConfirmationPage = macroElement.data('newsletter-confirmation-page')
    const newsletterMailingOption = macroElement.data('newsletter-mailing-option') || ''

    const form = $(this).parent().parent()
    const email = form.find('input[name=email]').val()

    termsWereChecked(form)

    let hasErrors = false

    if (!validateEmail(email)) {
      $(this).parent().parent().find('.error-message.email-input').show()
      hasErrors = true
    } else {
      $(this).parent().parent().find('.error-message.email-input').hide()
    }

    if (!isPhoneNumberValid(form)) {
      $(this).parent().parent().find('.error-message.phone-input').show()
      hasErrors = true
    } else {
      $(this).parent().parent().find('.error-message.phone-input').hide()
    }

    if (!termsWereChecked(form)) {
      hasErrors = true
    }

    if (hasErrors) return

    $(this).parent().parent().find('.error-message').hide()

    newsletterRegistration({ service, email, phone: form.find('input[name=mobile]').val(), confirmationPage: newsletterConfirmationPage, mailingOption: newsletterMailingOption })
  })
})

/**
 *
 * @param {Object} params
 * @param {String} params.service
 * @param {String} params.email
 * @param {String?} params.phone
 * @param {String} params.confirmationpage
 */
function newsletterRegistration ({ service, email, phone, confirmationPage, mailingOption }) {
  $.ajax({
    url: service,
    data: { email, phone, mailingOption },
    success: function (response) {
      if (response && (response.status === 'success')) {
        if (confirmationPage) {
          window.location.href = confirmationPage
        }
      }
    }
  })
}

function validateEmail (email) {
  const re = /^[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:[._-][a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*@[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:-[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,}(?:\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

/**
 *
 * @param {JQuery<Element>} form
 * @returns
 */
function termsWereChecked (form) {
  if (!form) return

  const checkbox = form.find('.terms input[type=checkbox]:visible')
  if (!checkbox.is(':checked')) {
    checkbox.parent().addClass('invalid')
    return false
  }
  checkbox.parent().removeClass('invalid')
  return true
}

/**
 *
 * @param {JQuery<Element>} form
 */
function isPhoneNumberValid (form) {
  if (form.find('input[name=mobile]').length && !form.find('input[name=mobile]').val()) return

  return true
}
