import $ from 'jquery' //eslint-disable-line
// import jQuery from 'jquery' //eslint-disable-line
// import 'bootstrap/dist/js/bootstrap.min.js'
// import 'popper.js/dist/popper.min.js'
// import 'bootstrap-select/dist/js/bootstrap-select.min.js'

$(document).ready(function () {
  const checkoutCompletedPage = $('#checkoutCompletedPageSalesforce')
  if (checkoutCompletedPage.length) {
    // manipulate navigation bar
    $('#header-step1').remove()
    $('#header-step2').remove()
    $('#header-stepOptionalDocumentation').remove()
    $('#header-step3').remove()
    $('#header-step4').remove()
    // manipulate navigation bar

    const backPage = $('.checkout__title .back-link a')
    backPage.click(function () {
      goBack()
    })

    $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
  }
})

/**
 * simulates the browser's back button
 */
function goBack () {
  if (window.history.length >= 2) {
    // if history is not empty, go back:
    window.history.back()
  }
}
