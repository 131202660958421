import $ from 'jquery'
$(document).ready(function () {
  var ball = $('.ball')
  if (ball.length) {
    $(document).mousemove(function (event) {
      var x = event.clientX * 30 / window.innerWidth + 35 + '%'
      var y = event.clientY * 30 / window.innerHeight + 35 + '%'
      ball.css('left', x)
      ball.css('top', y)
      ball.css('translate', '(-' + x + ',-' + y + ')')
    })
  }
})
