import $ from 'jquery'

$(document).ready(function () {
  var listLiknks = $('.listLinks')
  if (listLiknks.length) {
    var distTop
    var distListLiknks = listLiknks.offset().top - 500
    var listLinksHeight = listLiknks.height() + 300
    var distListLiknksHide = distListLiknks + listLinksHeight

    $(window).scroll(function () {
      distTop = $(this).scrollTop()
      if (distTop > distListLiknks && distTop < distListLiknksHide) {
        listLiknks.addClass('background')
      } else {
        listLiknks.removeClass('background')
      }
    })
  }

  var linkItem = $('.listLinks__wrapper--wordCloud .item')
  $(function () {
    $(window).resize(function () {
      $(linkItem).each(function () {
        var this_ = $(this)
        var this_offset = this_.offset().top // eslint-disable-line
        if (this_.next().length) {
          var next_offset = this_.next().offset().top // eslint-disable-line
        }

        if (next_offset > this_offset) { // eslint-disable-line
          this_.removeClass('bullet')
        } else {
          this_.addClass('bullet')
        };
      })
    }).resize()
  })
})
