import $ from 'jquery'

$(document).ready(function () {

  if($(".blogPageHeader").length){
    let btnFloatWrapper = $('.btnFloatWrapper');

    // show button
    btnFloatWrapper.addClass("active");
  }

  var openClose = $('.btnFloatWrapper__btn.kategori, .blogCategory .btnClose, .blogCategory .overlay')
  var blogCategoryWrapper = $('.blogCategory')
  var body = $('body')
  openClose.click(function () {
    blogCategoryWrapper.toggleClass('active')
    body.toggleClass('bodyBlock')
  })
})
