
/**
 * executes a request to the Google Analytics API to inform that a purchase has been completed
 * @param {*} $ jQUery
 * @param {Stritn} cartId the current shopping cart id
 * @param {Boolean} paymentMethod informs if the current step is the payment step
 * @param {Boolean} threestep informs if it is the normal flow of the checkout or if it is the checkout of the otherPayer form
 */
function processGADataLayerPurchase ($, cartId, paymentMethod, threestep = true) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const gaDataLayerService = page.data('ga-data-layer-service')
  // "creditcard", “downpayment”, “invoice” or “other”.
  $.ajax({
    url: gaDataLayerService,
    data: { cartId: cartId, action: 'purchase' },
    success: function (data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'purchase',
        applicationflow: threestep ? 'threestep' : 'singleflow',
        ecommerce: {
          purchase: {
            actionField: {
              id: data.orderNumber,
              revenue: data.price,
              affiliation: paymentMethod
            },
            products: data.products
          }
        }
      })
    }
  })
}

function getCustomerPaymentOption ($) {
  const selectedPaymentOption = $('.radio-card').find("input[type='radio'].active").attr('id')
  const otherPayerVariant = $('.option4.alternativePayment .alternativePayment__content .alternativePayment__content--items .item.dropDownCard.dropDownCard1 .selectedItem').attr('data-value')
  let paymentOption = ''
  let paymentOptionVariant
  switch (selectedPaymentOption) {
    case 'option1':
      paymentOption = 'credit-card'
      break

    case 'option2':
      paymentOption = 'invoice'
      break

    case 'option3':
      paymentOption = 'installments'
      break
    case 'option4':
      paymentOption = 'other-payer'
      paymentOptionVariant = otherPayerVariant
      break
  }
  const numberOfDownPaymentMonths = $('#option3').hasClass('active') ? parseInt($('.option3.alternativePayment .alternativePayment__content .alternativePayment__content--items .selectedItem').text()) : 0

  return {
    paymentOption: paymentOption, paymentOptionVariant: paymentOptionVariant, numberOfDownPaymentMonths: numberOfDownPaymentMonths
  }
}

/**
 * processes the invoice or installments payment method, sending updated information to the Salesforce API that returns whether or not payment is possible
 * @param {*} $ jQuery
 * @param {String} shoppingCartId the current shopping cart id
 * @param {Boolean} paymentStep indicates if the checkout is in the payment step
 * @param {Boolean} otherPayerForm indicates if it is the form of "otherPayer"
 * @returns {Object} the result of processing by Salesforce
 */
function processPayment ($, shoppingCartId, paymentStep, otherPayerForm) {
  let result
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
  const button = $('#checkoutCoursePageIDSalesforce').length ? $('#btn-step3') : $('#btn-conclude-payment-information')
  const optionRadioChecked = $('.checkout__section .radio-card input.active')
  const selectedMethod = optionRadioChecked.attr('id')

  const checkoutGeneralService = page.data('checkout-courses-general-service')

  const customerPaymentOption = getCustomerPaymentOption($)

  setLoadButton(button)
  window.setTimeout(function () {
    $.ajax({
      url: studentRegisterSalesforceService,
      // async: false,
      data: { shoppingCartId: shoppingCartId, action: 'opportunity', paymentStep: paymentStep, otherPayerForm: otherPayerForm, customerPaymentOption: JSON.stringify(customerPaymentOption) },
      success: function (data) {
        if (data) {
          result = data
          // result = {
          //   response: {
          //     creditcheck: {
          //       creditCheckstatus: true,
          //       customerId: 10006,
          //       creditRating: null,
          //       creditCheckcomments: 'BusinessLogic - Customer not found in external DB;id:10006, BusinessLogic - Customer not found in external DB;id:10004, BusinessLogic - Customer not found'
          //     }
          //   }
          // }
          if (result && result.response && result.response.creditcheck && result.response.creditcheck.creditCheckStatus && result.response.creditcheck.creditCheckStatus === true) {
            $.ajax({
              url: checkoutGeneralService,
              data: { shoppingCartId: shoppingCartId, action: 'finishCart' },
              success: function (data) {
                if (data && data.cart && data.cart.data && data.cart.data.status && data.cart.data.status === 'checkout_complete') {
                  if ($('#checkoutCoursePageIDSalesforce').length) {
                    const otherPayer = data && data.cart && data.cart.data && data.cart.data.status && data.cart.data.otherPayer
                    processGADataLayerPurchase($, shoppingCartId, selectedMethod === 'option2' ? 'invoice' : 'downpayment', !otherPayer)
                  }
                  goToConfirmationPage($)
                  goToScrollTop($, $('.page'))
                  $.removeCookie('shoppingCartIdSalesforce', { path: '/' })
                } else {
                  $('#checkoutCoursePageIDSalesforce').length ? appendErrorMessage($, $('.step3 .messageError'), selectedMethod) : appendErrorMessage($, $('.step1 .messageError'), selectedMethod)
                  goToScrollTop($, $('#addedMessageError'))
                  $(`#${selectedMethod}`).parent().remove()
                  removeLoadButton(button)
                }
              } // end success
            })
          } else {
            $('#checkoutCoursePageIDSalesforce').length ? appendErrorMessage($, $('.step3 .messageError'), selectedMethod) : appendErrorMessage($, $('.step1 .messageError'), selectedMethod)
            goToScrollTop($, $('#addedMessageError'))
            $(`#${selectedMethod}`).parent().remove()
            removeLoadButton(button)
          }
        }
      }
    })
  }, 500)
  return result
}

/**
 * if the payment operation is successful then the user is redirected to the payment screen.
 */
function goToConfirmationPage ($) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const cartIdFromReq = page.data('cartid-from-req')
  if (cartIdFromReq) {
    $('.step1').hide()
    $('.step2').hide()
    $('.step3').hide()
    $('.step4').show()

    $('.header-step1').removeClass('active done')
    $('.header-step1').addClass('disabled')
    $('.header-step2').removeClass('active done')
    $('.header-step2').addClass('disabled')
    $('.header-stepOptionalDocumentation').removeClass('active done')
    $('.header-stepOptionalDocumentation').addClass('disabled')
    $('.header-step3').removeClass('active done')
    $('.header-step3').addClass('disabled')
    $('.header-step4').removeClass('disabled')
    $('.header-step4').addClass('active')

    const customerInfo = page.data('get-cart-customer-info')

    if (customerInfo) {
      $.ajax({
        url: customerInfo,
        type: 'POST',
        data: { cartIdFromReq: cartIdFromReq },
        success: function (data) {
          if (data) {
            // console.log(data)
            // append to section: Takk for din bestilling
            $('.checkout__text-info.withTitle.col-lg-8').append(`<ul>
            <li>
              I løpet av et par dager vil du motta bekreftelse på din
              påmelding samt bruker og passord til din e-post: <a
                 ${data.inputEmail ? `href=mailto: ${data.inputEmail}` : ''}
                 class="link-b-orange">${data.inputEmail ? `${data.inputEmail}` : ''}</a>
            </li>
          </ul>
          <h6>Er det noe du lurer på? Kontakt oss gjerne:</h6>
          <p>man-fre: 09-16</p>
          <p>67 58 88 00</p>`)

            // append to section: Student
            $('.checkout__section.student.customerInformation').append(`<div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputName">Navn</label>
              ${data.inputName && data.inputLastName ? `<div>${data.inputName} ${data.inputLastName}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputEmail">E-post </label>
            ${data.inputEmail ? `<div>${data.inputEmail}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
            <label for="inputPhoneNumber">Telefonnummer</label>
              ${data.inputPhoneNumber ? `<div>${data.inputCountryCode || ''}${data.inputPhoneNumber}</div>` : ''}
            </div>
          </div>
          <div class="row resize-col">
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputAddress">Gate/postboks</label>
              ${data.inputAddress ? `<div>${data.inputAddress}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputNumber">Sted</label>
              ${data.inputZipCode ? `<div>${data.inputZipCode}</div>` : ''}
            </div>
            <div class="form-group form-readonly col-md-8 col-lg-4">
              <label for="inputLand">Land</label>
              ${data.inputCountry && data.inputCountry.name ? `<div>${capitalize(data.inputCountry.name, true)}</div>` : ''}
            </div>
          </div>`)
          }
        } // end success
      })
    }
  }
}

/**
 * apply capitalization to a string
 * @param {String} str string that must be modified
 * @param {Boolean} lower apply lower case to string
 * @returns {String} modified string
 */
function capitalize (str, lower = false) {
  // console.log(str)
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

/**
 * scrolls page to selected element
 * @param {*} element
 */
function goToScrollTop ($, element) {
  $('html, body').animate(
    {
      scrollTop: element.offset().top - 150
    }, 700)
}

/**
 * adds an error message to the page if the payment method fails.
 * @param {*} htmlSectionToAppend section where the error message should be added
 */
function appendErrorMessage ($, htmlSectionToAppend, selectedMethod) {
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const strings = page.data('strings')
  // const hasMessageErrorOnPage = $('#addedMessageError')
  // const responseCode = page.data('response-code')
  const message = selectedMethod === 'option3' ? `${strings.installmentNotAvailable}` : `${strings.invoiceIsNotAvailable}`
  // const htmlSectionToAppend = $('.step3 .messageError')
  const messageError = $('#addedMessageError')

  if (!messageError.length) {
    htmlSectionToAppend.prepend(
      `<div class='checkout__section' id='addedMessageError'>
        <div class="row resize-col">
          <div class="col-lg-10">
            <div class="box-orange">
              <p>${message}</p>
            </div>
          </div>
        </div>
      </div>`
    )
  } else {
    $('#addedMessageError .box-orange p').html(message)
  }
}

/**
 * activates a button spinner
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

module.exports = {
  processPayment
}
