import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js'

$(document).ready(function () {
  if (window.location.hash !== '' && window.location.hash !== null) {
    $(`${window.location.hash} button`).removeClass('collapsed')
    $(`${window.location.hash} button`).attr('aria-expanded', true)
    const collapse = '#collapse-' + (window.location.hash).split('#')[1]
    $(collapse).addClass('show')
  }

  const faqQuestion = $('.faqPartUrl .accordion .card .card-header')
  const faqPartUrl = $('.faqPartUrl')
  faqQuestion.click(function () {
    const url = faqPartUrl.data('url') + '#' + $(this).attr('id')
    window.history.pushState('object or string', 'Title', url)
  })
})
