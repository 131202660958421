import $ from 'jquery'

$(document).ready(function () {
  const studyAdviceForm = $('.studieradgiving')
  var body = $('body')
  if (studyAdviceForm.length) {
    var open = $('header .btnStudieradgiving, footer .btnStudieradgiving')
    var close = $('.studieradgiving .overlay, .studieradgiving .btnClose')
    var logInWrapper = $('.studieradgiving')
    open.click(function () {
      logInWrapper.addClass('active')
      body.addClass('bodyBlock')
    })
    close.click(function () {
      logInWrapper.removeClass('active')
      if (!((body.hasClass('android') || body.hasClass('ios')) && $('.dropDownMenu').hasClass('active'))) {
        body.removeClass('bodyBlock')
      }
    })

    sendData()
  }
})

function sendData () {
  $('#studieradgiving-form .btnSend button').on('click', null, function (event) {
    event.preventDefault()
    const postUrl = $('.studieradgiving').data('post-url')

    const email = $('#inputEmailStudyAdvice').val()

    const validEmail = validateEmail(email)

    if (!validEmail) {
      const inputEmailElement = $('#inputEmailStudyAdvice')

      inputEmailElement.attr('placeholder', 'Påkrevd')
      inputEmailElement.addClass('invalid')
      inputEmailElement.addClass('invalidField')
    } else {
      $('#studieradgiving-form').attr('action', postUrl)
      $('#studieradgiving-form').submit()
    }
  })
}

function validateEmail (email) {
  const re = /^[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:[._-][a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*@[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:-[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,}(?:\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}
