
import $ from 'jquery'

$(document).ready(function () {
  var btnImportantMessage = $('.importantMessage .importantMessage__btn a')
  var contentImportantMessage = $('.importantMessage .importantMessage__content')

  btnImportantMessage.click(function () {
    contentImportantMessage.slideToggle(300)
    $(this).toggleClass('active')
  })

  addLinkArrow(btnImportantMessage)
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(' ')
      var last = text.pop()
      var lastWord = last.substring(0, (last.length - 1))
      var lastChar = last.split('')
      var char = lastChar.pop()
      return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
    })
  }
})
