import $ from 'jquery'

$(document).ready(function () {

  if($(".subLandingPageHeader-sideinfo").length){
    if ($(window).width() > 992) {
      let pageTitle = $(".subLandingPageHeader-sideinfo h1");
      let pageIntro = $(".subLandingPageHeader-sideinfo__intro");

      // Check if page title has over-flown
      if(checkOverflow(pageTitle) == true){

        // Place the intro text below the title
        pageIntro.parent().removeClass().addClass("offset-lg-1 col-lg-8 mt-4");
      }
    }

    // Function that returns if an object text has over-flown
    function checkOverflow( element ){
      if (element[0].scrollWidth > (element.innerWidth()) + 1){
        return true; // Text has over-flown
      } else {
        return false;
      }
    }
  }
})
