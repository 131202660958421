/**
 * apply a discount coupon in the shopping cart if it is valid
 * @param {*} $ jQuery
 * @param {*} jQuery jQuery
 * @param {String} couponCodeFromCart shopping cart coupon code, if exist
 * @returns {Object} an object containing coupon data applied to the shopping cart
 */
function applyCouponDiscount ($, jQuery, couponCodeFromCart = undefined, updateSalesforceOpportunity, createOrUpdateOpportunitySalesforce, displaySalesforcePageError, checkInstallmentsCallback) {
  const button = $('#btn-step3')
  const page = $('#checkoutCoursePageIDSalesforce')
  const shoppingCartId = page.data('cartid-from-req')
  const serviceUrl = page.data('checkout-courses-general-service')
  const couponElement = $('#inputDiscountCoupon')
  const coupon = { validCoupon: false }
  if (couponElement.length) {
    const couponCode = couponElement.val() || couponCodeFromCart

    if (couponCode) {
      // console.log(serviceUrl)
      // setLoadButton(button)
      couponElement.prop('disabled', true)
      $.ajax({
        url: serviceUrl,
        async: false,
        data: jQuery.param({ shoppingCartId: shoppingCartId, couponCode: couponCode, action: 'setSalesforceCouponCode' }),
        success: function (data) {
          if (data) {
            if (updateSalesforceOpportunity && data.couponCode) {
              $('.basket__total .value').addClass('loading-background')
              setLoadButton(button)
              couponElement.parent().addClass('loading')

              const studentRegisterSalesforceService = page.data('student-register-salesforce-service')
              $.ajax({
                url: studentRegisterSalesforceService,
                // async: false,
                data: { shoppingCartId: shoppingCartId, action: 'opportunity', paymentStep: true },
                success: function (data) {
                  if (data) {
                    // result = data
                    const orderId = data && data.orderId
                    const couponIsValid = data.couponCodes && data.couponCodes.status && data.couponCodes.status === 'valid'
                    if (!orderId) {
                      displaySalesforcePageError($('#inputDiscountCoupon'))
                    }
                    if (couponIsValid) {
                      couponElement.removeClass('invalidField')
                      couponElement.addClass('valid')
                      coupon.validCoupon = true
                      coupon.totalPrice = data.priceInfo
                    } else {
                      couponElement.addClass('invalidField')
                      couponElement.removeClass('valid')
                      coupon.validCoupon = false
                    }
                    couponElement.parent().removeClass('loading')
                    removeLoadButton(button)
                    couponElement.prop('disabled', false)
                  }

                  udpatePriceInfo($, coupon, checkInstallmentsCallback)
                  $('.basket__total .value').removeClass('loading-background')
                }
              })
            } else {
              removeLoadButton(button)
              couponElement.prop('disabled', false)
            }
          }
        } // end success
      })
    } else {
      couponElement.removeClass('invalidField')
      couponElement.removeClass('valid')
      $.ajax({
        url: serviceUrl,
        data: jQuery.param({ shoppingCartId: shoppingCartId, couponCode: couponCode, action: 'setSalesforceCouponCode' }),
        success: function (data) {
          if (data) {
            udpatePriceInfo($, { noCoupon: true }, checkInstallmentsCallback)
          }
        } // end success
      })
    }
  }

  //   console.log(validCoupon)
  return coupon
}

/**
 * retrieves the updated total price from a shopping cart
 * @param {*} $ jQuery
 * @returns an object containing the prices after the coupon is applied
 */
function retrieveTotalPrice ($) {
  // const button = $('#btn-step3')
  const page = $('#checkoutCoursePageIDSalesforce').length ? $('#checkoutCoursePageIDSalesforce') : $('#checkoutOtherPayerSalesforce')
  const shoppingCartId = page.data('cartid-from-req')
  const serviceUrl = page.data('checkout-courses-general-service')
  let dataPrice
  // setLoadButton(button)
  $.ajax({
    url: serviceUrl,
    async: false,
    data: { shoppingCartId: shoppingCartId, action: 'retrievePrice' },
    success: function (data) {
      if (data) {
        // console.log(data)
        dataPrice = data
        // removeLoadButton(button)
      }
    } // end success
  })

  return dataPrice
}

/**
 * gets the coupon code from the shopping cart
 * @param {*} $ jQuery
 * @returns the coupon code
 */
function getCouponCode ($) {
  let coupon
  const page = $('#checkoutCoursePageIDSalesforce')
  const shoppingCartId = page.data('cartid-from-req')
  $.ajax({
    url: page.data('checkout-courses-general-service'),
    async: false,
    data: { shoppingCartId: shoppingCartId, action: 'getCouponCode' },
    success: function (data) {
      if (data) {
        coupon = data && data.couponCode
      }
    } // end success
  })
  return coupon
}

/**
 * update the total price in the page
 * @param {*} $ jQuery
 * @param {object} couponInfo info about the coupon returned
 * @param {Function} checkInstallmentsCallback callback function to check the installments option selected and adjust the total price
 */

function udpatePriceInfo ($, couponInfo, checkInstallmentsCallback) {
  const page = $('#checkoutCoursePageIDSalesforce')
  const strings = page.data('strings')

  if (!couponInfo.noCoupon) {
    if (!couponInfo.validCoupon) { // invalid coupon
      const dataPrice = retrieveTotalPrice($)
      $('.step1 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.step2 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.stepOptionalDocumentation .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.step3 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
      $('.step4 .checkout__close__basket__total .value').text(dataPrice.totalPrice.totalPriceWithMask)
      goToScrollTop($, $('#inputDiscountCoupon'))
      if ($('.couponDiscountBasket').length) {
        $('li.couponDiscountBasket').remove()
      }

      $('.combining-coupons-message').hide()

      if (dataPrice.totalPrice.discountFromStudiesSubtraction) {
        $('[data-original-price]').each(function () {
          const originalPrice = $(this).attr('data-original-price')
          const priceWithDiscount = $(this).attr('data-price-with-discount')
          if (originalPrice !== priceWithDiscount) {
            $(this).html(`<s>${originalPrice}</s><span>${priceWithDiscount}</span>`)
          } else {
            $(this).html(`<span>${priceWithDiscount}</span>`)
          }
        })
      }
    } else { // valid coupon
      $('.step1 .basket__total__value .value').text(couponInfo.totalPrice.totalPriceWithMask)
      $('.step2 .basket__total__value .value').text(couponInfo.totalPrice.totalPriceWithMask)
      $('.stepOptionalDocumentation .basket__total__value .value').text(couponInfo.totalPrice.totalPriceWithMask)
      $('.step3 .basket__total__value .value').text(couponInfo.totalPrice.totalPriceWithMask)
      $('.step4 .checkout__close__basket__total .value').text(couponInfo.totalPrice.totalPriceWithMask)

      if ($('.couponDiscountBasket').length) {
        $('li.couponDiscountBasket').remove()
      }

      if (couponInfo.totalPrice.isCombiningCoupons) $('.combining-coupons-message').fadeIn()

      if (couponInfo.totalPrice.discountSubtraction) {
        $('.checkout__bottom.basket__total .basket__total__items, .checkout__section .checkout__close__basket .items').append(
          `
          <li class="couponDiscountBasket">
              <div>${strings.couponDiscount}</div>
              <div class="value">${couponInfo.totalPrice.discountSubtraction}</div>
          </li>
          `
        )

        $('[data-original-price]').each(function () {
          const originalPrice = $(this).attr('data-original-price')
          $(this).html(`<span>${originalPrice}</span>`)
        })
      }

      if (couponInfo.totalPrice.discountFromStudiesSubtraction) {
        $('[data-original-price]').each(function () {
          const originalPrice = $(this).attr('data-original-price')
          const priceWithDiscount = $(this).attr('data-price-with-discount')
          if (originalPrice !== priceWithDiscount) {
            $(this).html(`<s>${originalPrice}</s><span>${priceWithDiscount}</span>`)
          } else {
            $(this).html(`<span>${priceWithDiscount}</span>`)
          }
        })
      }
    }
  } else { // without coupon
    const dataPrice = retrieveTotalPrice($)
    $('.step1 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
    $('.step2 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
    $('.stepOptionalDocumentation .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
    $('.step3 .basket__total__value .value').text(dataPrice.totalPrice.totalPriceWithMask)
    $('.step4 .checkout__close__basket__total .value').text(dataPrice.totalPrice.totalPriceWithMask)
    if ($('.couponDiscountBasket').length) {
      $('li.couponDiscountBasket').remove()
    }

    $('.combining-coupons-message').hide()

    if (dataPrice.totalPrice.discountFromStudiesSubtraction) {
      $('[data-original-price]').each(function () {
        const originalPrice = $(this).attr('data-original-price')
        const priceWithDiscount = $(this).attr('data-price-with-discount')
        if (originalPrice !== priceWithDiscount) {
          $(this).html(`<s>${originalPrice}</s><span>${priceWithDiscount}</span>`)
        } else {
          $(this).html(`<span>${priceWithDiscount}</span>`)
        }
      })
    }
  }

  if (typeof checkInstallmentsCallback === 'function') {
    checkInstallmentsCallback()
  }
}

/**
 * scrolls page to selected element
 * @param {*} element
 * @param {Number} h height the page should scroll to the element.
 */
function goToScrollTop ($, element, h = 20) {
  if (element.length) {
    $('html, body').animate(
      {
        scrollTop: element.offset().top - h
      }, 700)
  }
}

/**
 * activates a button spinner
  * @param $ jQuery
 * @param {*} element
 */
function setLoadButton (element) {
  element.prop('disabled', true)
  element.css('opacity', '1')
  element.addClass('btn--loading')
}

/**
 * disables the button spinner
 * @param $ jQuery
 * @param {*} element
 */
function removeLoadButton (element) {
  element.removeClass('btn--loading')
  element.prop('disabled', false)
}

module.exports = {
  applyCouponDiscount,
  retrieveTotalPrice,
  getCouponCode
}
