import $ from 'jquery'

$(document).ready(function () {
  // Add CTA arrow
  var bannerCtaArrow = $('.banner__wrapper--buttonWrapper .content .button div')
  addLinkArrow(bannerCtaArrow)
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(' ')
      var last = text.pop()
      var lastWord = last.substring(0, (last.length - 1))
      var lastChar = last.split('')
      var char = lastChar.pop()
      return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
    })
  }
  // Add CTA arrow

  const ua = navigator.userAgent
  const os = /Android/.test(ua)
    ? 'android'
    : /iPhone/.test(ua)
      ? 'ios'
      : /iPod/.test(ua)
        ? 'ios'
        : /iPad/.test(ua)
          ? 'ios'
          : 'default'
  const bodyelement = document.getElementsByTagName('BODY')[0]
  switch (os) {
    case 'android':
      bodyelement.classList.add('android')
      break
    case 'ios':
      bodyelement.classList.add('ios')
      break
    default:
      bodyelement.classList.add('default')
  }

  // input clear button (x)
  const inputsWithClearButton = $('.hasClearButton')
  $.each(inputsWithClearButton, function (index, value) {
    $('<button class="clear-input" aria-label="Clear input" tabindex="-1"></button>').insertAfter($(this))
  })
  $('.clear-input').on('click', function () {
    $(this).prev('input').val('')
  })
})
