/**
 * When the page loads, it checks if there is a #calendly on the URL. If so, the modal is shown.
 *
 * The function also goes through each link which href ends with the same path as Calendly selected on site.xml.
 * Those ones found will have the href changed to the current URL page + #calendly at the end
 */
document.addEventListener('DOMContentLoaded', () => {
  const currentHref = document.location.href
  const modal = document.getElementById('calendly-modal')
  const calendlyPath = modal && modal.getAttribute('data-calendly-path')
  const links = calendlyPath && document.querySelectorAll(`[href$='${calendlyPath}']`)

  if (links) {
    links.forEach(item => {
      item.setAttribute('href', `${currentHref.replace('#calendly', '')}#calendly`)
      item.addEventListener('click', () => {
        showModal(modal)
      })
    })
  }

  if (currentHref.includes('#calendly') && modal) {
    showModal(modal)
  }

  const closeModalButton = modal?.querySelector('button.close-calendly-modal')
  closeModalButton?.addEventListener('click', () => closeModal(modal))
})

/**
 * Fills the iFrame souce inside the modal and shows it
 * @param {HTMLElement} modal
 * @returns
 */
const showModal = (modal) => {
  const iFrameURL = modal.getAttribute('data-iframe-url')

  if (!iFrameURL) return

  const iFrameElement = modal.querySelector('iframe')

  if (!iFrameElement) return
  if (iFrameElement.src === iFrameURL) {
    modal.showModal()
    return
  }

  iFrameElement.src = iFrameURL

  modal.showModal()
}

/**
 * Close the modal
 * @param {HTMLElement} modal
 */
const closeModal = (modal) => {
  modal.close()
}
