export function parseChars (text) {
  if (!text) return
  text = text.replace(new RegExp('ø', 'g'), 'o')
  text = text.replace(new RegExp('&amp;oslash;', 'g'), 'ø')

  text = text.replace(new RegExp('Ø', 'g'), 'O')
  text = text.replace(new RegExp('&amp;Oslash;', 'g'), 'Ø')

  text = text.replace(new RegExp('Æ', 'g'), 'AE')
  text = text.replace(new RegExp('&amp;Aelig;', 'g'), 'AE')

  text = text.replace(new RegExp('&AElig;', 'g'), 'AE')
  text = text.replace(new RegExp('&amp;AElig;', 'g'), 'AE')

  text = text.replace(new RegExp('æ', 'g'), 'ae')
  text = text.replace(new RegExp('&amp;aelig;', 'g'), 'ae')

  text = text.replace(new RegExp('å', 'g'), 'a')
  text = text.replace(new RegExp('&amp;aring;', 'g'), 'a')

  text = text.replace(new RegExp('Å', 'g'), 'A')
  text = text.replace(new RegExp('&amp;Aring;', 'g'), 'Å')

  text = text.replace(new RegExp('Ä', 'g'), 'A')
  text = text.replace(new RegExp('&amp;Auml;', 'g'), 'Ä')

  text = text.replace(new RegExp('ä', 'g'), 'a')
  text = text.replace(new RegExp('&amp;auml;', 'g'), 'ä')

  text = text.replace(new RegExp('Ð', 'g'), 'ETH')
  text = text.replace(new RegExp('&amp;ETH;', 'g'), 'Ð')

  text = text.replace(new RegExp('ð', 'g'), 'eth')
  text = text.replace(new RegExp('&amp;eth;', 'g'), 'ð')

  text = text.replace(new RegExp('Ö', 'g'), 'O')
  text = text.replace(new RegExp('&amp;Ouml;', 'g'), 'Ö')

  text = text.replace(new RegExp('ö', 'g'), 'o')
  text = text.replace(new RegExp('&amp;ouml;', 'g'), 'ö')

  text = text.replace(new RegExp('Þ', 'g'), 'Þ')
  text = text.replace(new RegExp('&amp;THORN;', 'g'), 'Þ')

  text = text.replace(new RegExp('á', 'g'), 'a')
  text = text.replace(new RegExp('Á', 'g'), 'A')

  text = text.replace(new RegExp('é', 'g'), 'e')
  text = text.replace(new RegExp('É', 'g'), 'E')

  text = text.replace(new RegExp('í;', 'g'), 'i')
  text = text.replace(new RegExp('Í', 'g'), 'I')

  text = text.replace(new RegExp('ó', 'g'), 'o')
  text = text.replace(new RegExp('Ó', 'g'), 'O')

  text = text.replace(new RegExp('ú', 'g'), 'u')
  text = text.replace(new RegExp('Ú', 'g'), 'U')

  text = text.replace(new RegExp('à', 'g'), 'a')
  text = text.replace(new RegExp('À', 'g'), 'A')

  text = text.replace(new RegExp('è', 'g'), 'e')
  text = text.replace(new RegExp('È', 'g'), 'E')

  text = text.replace(new RegExp('ì', 'g'), 'i')
  text = text.replace(new RegExp('Ì', 'g'), 'I')

  text = text.replace(new RegExp('ò', 'g'), 'o')
  text = text.replace(new RegExp('Ò', 'g'), 'O')

  text = text.replace(new RegExp('ù', 'g'), 'u')
  text = text.replace(new RegExp('Ù', 'g'), 'U')

  text = text.replace(new RegExp('â', 'g'), 'a')
  text = text.replace(new RegExp('Â', 'g'), 'A')

  text = text.replace(new RegExp('ê', 'g'), 'e')
  text = text.replace(new RegExp('Ê', 'g'), 'E')

  text = text.replace(new RegExp('î', 'g'), 'i')
  text = text.replace(new RegExp('Î', 'g'), 'I')

  text = text.replace(new RegExp('ô', 'g'), 'o')
  text = text.replace(new RegExp('Ô', 'g'), 'O')

  text = text.replace(new RegExp('û', 'g'), 'u')
  text = text.replace(new RegExp('Û', 'g'), 'U')

  return text
}
