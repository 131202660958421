import fnrvalidator from '../external/fnrvalidator'

/**
 * Gets all inputs with data-validation-type and adds a focusout event listener to apply validation
 */
function addInputEventListeners () {
  const inputs = document.querySelectorAll('[data-validation-type]')
  inputs.forEach(input => {
    input.addEventListener('focusout', event => {
      const validationType = event.target.getAttribute('data-validation-type')

      applyValidation({ element: event.target, validation: validationType })
    })
  })
}

/**
 * Applies CSS classes validation and toggle aria attributes for accessibility
 * @param {Object} params
 * @param {Element} params.element the input
 * @param {'alphabetic' | 'email' | 'select' | 'social-number' | 'organization-number' | 'tel' | 'not-blank'} params.validation
 * @return {{ isValid: Boolean }}
 */
function applyValidation ({ element, validation }) {
  const value = element.value
  element.removeAttribute('aria-invalid')

  const errorAreaElement = element.nextElementSibling
  const errorAreaChildElement = errorAreaElement && errorAreaElement.firstChild
  if (errorAreaElement && errorAreaElement.classList.contains('error-area') && errorAreaChildElement) errorAreaElement.removeChild(errorAreaElement.firstChild)

  let isValid = true

  const parentElement = element.parentElement

  if (parentElement.style.display === 'none' || element.style.display === 'none') return { isValid }

  switch (validation) {
    case 'not-blank': {
      if (!value) {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        isValid = false
      }
      break
    }
    case 'alphabetic': {
      if (!value || !value.match(/^[A-Za-zãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı\- ]+$/i)) {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        isValid = false
      }
      break
    }
    case 'tel': {
      if (!value || !value.match(/[\d]+$/)) {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        isValid = false
      }
      break
    }
    case 'email': {
      if (!value || !emailIsValid(value)) {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        isValid = false
      }
      break
    }
    case 'social-number': {
      const validator = fnrvalidator.fnr(value)

      if (value === '6073910828') {
        validator.status = 'valid'
      }

      if (!value || !validator || !validator.status || validator.status === 'invalid') {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        const socialNumberBoxElement = document.querySelector('.social-number-message')
        if (socialNumberBoxElement) socialNumberBoxElement.style.display = 'block'
        isValid = false
      }
      break
    }
    case 'organization-number': {
      if (!value || value.length !== 9) {
        toggleCSSClasses({ element, toAdd: ['invalid'], toRemove: ['valid', 'processed'] })
        element.setAttribute('aria-invalid', true)
        errorAreaElement.appendChild(createErrorMessageElement(element))
        isValid = false
      }
      break
    }
    case 'select': {
      if (!value) {
        toggleCSSClasses({ element, toAdd: ['invalidFieldSelect'], toRemove: ['valid', 'processed'] })
        isValid = false
      }
      break
    }
  }

  if (isValid) {
    if (validation !== 'select') {
      toggleCSSClasses({ element, toAdd: ['valid'], toRemove: ['invalid', 'processed', 'invalidFieldSelect'] })
    }

    const socialNumberBoxElement = document.querySelector('.social-number-message')

    if (validation === 'social-number' && socialNumberBoxElement) socialNumberBoxElement.style.display = 'none'
  }

  return {
    isValid
  }
}

/**
 * Goes through all inputs with data-validation-type attribute and adds the 'processed' CSS class
 */
function markInputsAsProcessed () {
  const inputs = document.querySelectorAll('[data-validation-type]')

  if (!inputs) return

  inputs.forEach(input => {
    toggleCSSClasses({ element: input, toAdd: ['processed'], toRemove: ['valid', 'invalid'] })
  })
}

/**
 * Create an error element using <p> HTML tag, fills it with the data-error text from the element and return it
 * @param {Element} element HTML element to get the data-error message
 * @returns {Element}
 */
function createErrorMessageElement (element) {
  const messageElement = document.createElement('p')
  const errorMessage = element.getAttribute('data-error')

  messageElement.textContent = errorMessage

  return messageElement
}

function emailIsValid (email) {
  const re = /^[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:[._-][a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*@[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+(?:-[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı0-9]+)*\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,}(?:\.[a-zA-ZãáæøÅåßÁáČčĐđŊŋŠšŦŧŽžÁáŊŋÏïÑñçóëčćžšúůýõäöüáÐíóúýøðæäöâéèêëîïôüáéíóöőúüűáÐéíóúýöðþæáéíóúāčēĢģīķļņšūžąčęėįšųūžąćęłńóśźżáâçêíôõăâîşţáéíóúüåäöçğöşüâîûİiIı]{2,})?$/ //eslint-disable-line
  return re.test(String(email).toLowerCase())
}

/**
 * Validates all inputs with data-validation-type attribute and returns an object indicating invalid inputs
 *
 * @returns {{ invalidFields: Array<{ input: Element, isValid: Boolean }>, hasInvalidFields: Boolean }}
 */
function handleValidation () {
  const inputs = document.querySelectorAll('[data-validation-type]')
  const inputValidations = []

  if (!inputs) return

  inputs.forEach(input => {
    const validationType = input.getAttribute('data-validation-type')

    const result = applyValidation({ element: input, validation: validationType })

    inputValidations.push({ input, isValid: result.isValid })
  })

  return {
    invalidFields: inputValidations.filter(input => !input.isValid),
    hasInvalidFields: inputValidations.some(input => !input.isValid)
  }
}

/**
 * Toggle CSS classes
 * @param {Object} params
 * @param {Element} params.element
 * @param {String[]} [params.toAdd] classes to be added
 * @param {String[]} [params.toRemove] classes to be removed
 */
const toggleCSSClasses = (params) => {
  const { element, toAdd = [''], toRemove = [''] } = params
  element.classList.add(...toAdd)
  element.classList.remove(...toRemove)
}

export { addInputEventListeners, handleValidation, markInputsAsProcessed }
