import $ from 'jquery'
import 'slick-carousel/slick/slick.min.js'

$(document).ready(function () {
  const carousel = $('.carousel.full .carousel__slider, .carousel.half .carousel__slider')

  const nextButton = carousel.parent()
    .parent()
    .parent()
    .parent()
    .data('next-button')

  $('.carousel.full .carousel__slider').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      `<button type="button" class="slick-next"><img src=${nextButton} alt=""></button>`
  })

  $('.carousel.half .carousel__slider').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow:
      `<button type="button" class="slick-next"><img src=${nextButton} alt=""></button>`,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
})
