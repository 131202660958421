document.addEventListener('DOMContentLoaded', function () {
  // Select all iframe elements on the page
  var iframes = document.querySelectorAll('iframe')
  const isInCoursePage = !!document.getElementById('coursePage')

  iframes.forEach(function (iframe) {
    // Get the value of the src attribute of the current iframe
    var src = iframe.getAttribute('src')

    // Check if the src attribute contains the word "loopify"
    if (src && src.includes('loopify') && !isInCoursePage) {
      // Get the parent element of the current iframe
      var parent = iframe.parentElement

      // Check if a parent element exists
      if (parent) {
        // Add the "loopify-newsletter" class to the parent element
        parent.classList.add('loopify-newsletter')
      }
    }
  })
})
