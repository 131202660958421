import $ from 'jquery'
$(document).ready(function () {
  var open = $('header .btnLogIn')
  var close = $('.logIn__wrapper .btnClose, .logIn .overlay')
  var logInWrapper = $('.logIn')
  var body = $('body')
  open.click(function () {
    logInWrapper.addClass('active')
    body.addClass('bodyBlock')
  })
  close.click(function(){
    logInWrapper.removeClass('active')
    if(!((body.hasClass('android') || body.hasClass('ios')) && $('.dropDownMenu').hasClass('active'))){
        body.removeClass('bodyBlock')
    }
  })

  // Add link arrow
  var loginlinkArrow = $('.login--link-external')
  addLinkArrow(loginlinkArrow)
  function addLinkArrow (el) {
    el.html(function () {
      var text = $(this).text().trim().split(' ')
      var last = text.pop()
      var lastWord = last.substring(0, (last.length - 1))
      var lastChar = last.split('')
      var char = lastChar.pop()
      return text.join(' ') + (last.length ? ' ' + lastWord + '<span>' + char + '</span>' : last)
    })
  }
})
