import $ from 'jquery'
import '../external/jquery-cookie.js'

$(document).ready(function () {
  // setup before functions
  var typingTimer // timer identifier
  var doneTypingInterval = 400 // time in ms, 0.4s second for example
  var $input = $('#autocompleteFrontpage') // on keyup, start the countdown
  var searchResult = $('.search-bar-wrapper__suggestion')
  var searchPageUrl = $('.search-bar-wrapper').data('search-page-url')
  var searchSuggestions = $('.search-bar-wrapper').data('search-suggestions').replace('[', '').replace(']', '').split(',')
  var totalChar
  var lastSearches = ($.cookie('lastSearches') && $.cookie('lastSearches').split(',')) || []
  var activeSearch = $('.main__menu--desktop .item.search, .main__menu--mobile .item.search, .dropDownMenu__desktop .item.search, .search-bar-wrapper .btn-close')

  // If exists hitory it should appear before the suggestion list
  if (lastSearches.length > 0) {
    getSuggestions(searchResult, searchSuggestions, searchPageUrl)
    loadResults($input)
  }

  $input.keyup(function () {
    clearTimeout(typingTimer)
    totalChar = $input.val().length

    if (totalChar > 2) {
      if ($input.val()) {
        typingTimer = setTimeout(doneTyping, doneTypingInterval)
      }
    } else {
      typingTimer = setTimeout(function () {
        getSuggestions(searchResult, searchSuggestions, searchPageUrl)
        loadResults($input)
      }, doneTypingInterval)
    }
  })

  activeSearch.click(function (el) {
    getSuggestions(searchResult, searchSuggestions, searchPageUrl)
    loadResults($input)
  })
}) // user is "finished typing," do something

function getSuggestions (searchResult, searchSuggestions, searchPageUrl) {
  searchResult.empty() // Clean items
  var lastSearches = ($.cookie('lastSearches') && $.cookie('lastSearches').split(',')) || []
  var historyAndSuggestions = lastSearches.concat(searchSuggestions)
  historyAndSuggestions.forEach(suggestion => {
    var link = searchPageUrl ? `${searchPageUrl}?s=${encodeURI(suggestion.trim())}` : 'javascript:void(0)'
    addItemInList(searchResult, link, suggestion.trim())
  })
}

function addItemInList (searchResult, url, title) {
  searchResult.append(`
        <a href="${url}">
            <div class="search-bar-wrapper__suggestion__item">
                <div class="container">
                    <div class="row">
                        <div class="offset-lg-1 col-lg-9"
                              data-th-text="${title}">${title}
                        </div>
                    </div>
                </div>
            </div>
        </a>
        `)
}

function loadResults (input) {
  var itemsResult = $('.search-bar-wrapper__suggestion a')

  itemsResult.each(function (index) {
    $(this).click(function (event) {
      event.preventDefault()
      input.val('')
      var thisUrl = $(this).attr('href')
      window.location.assign(thisUrl)
    })
  })
}

function doneTyping () {
  // do something
  getAutocompleteResults()
}

function getAutocompleteResults () {
  var $input = $('#autocompleteFrontpage')
  var searchTerm = $input.val()
  var autocompleteServiceUrl = $input.data('autocomplete-service')
  var searchResult = $('.search-bar-wrapper__suggestion')
  var searchPageUrl = $('.search-bar-wrapper').data('search-page-url')
  var searchSuggestions = $('.search-bar-wrapper').data('search-suggestions').replace('[', '').replace(']', '').split(',')
  var totalChar

  if ($input.val()) {
    totalChar = $input.val().trim().length

    if (totalChar > 2 && autocompleteServiceUrl) {
      $.ajax({
        url: autocompleteServiceUrl,
        data: {
          searchTerm: searchTerm
        },
        success: function success (data) {
          if (data.result.length) {
            searchResult.empty() // Clean all items
            $.each(data.result, function (key, value) {
              var link = searchPageUrl ? `${searchPageUrl}?s=${value.title}` : 'javascript:void(0)'
              addItemInList(searchResult, link, value.title)
            })
          } else {
            getSuggestions(searchResult, searchSuggestions, searchPageUrl)
          }
          loadResults($input)
        } // end success
      })
    }
  }
}
